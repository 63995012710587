/* =================
  MEDIA
==================== */

/* REMOTE VIDEO
-------------------- */
.field--name-field-media-oembed-video{
	padding-bottom: 56.25%;
	position: relative;
	.label{
		display: none;
	}
	iframe{
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

/* EMBEDDED MEDIA
--------------------- */
/* PRE D10 - CAN REMOVE ONCE UPGRADE IS LIVE
.oc-media{
	max-width: 100%;
	@include spacer(1.5);
	&.left,&.align-left{
		float: none;
		@include tab{
			float: left;
			max-width: 50%;
			margin-right: 20px;
			&.remote_video{
				width: 100%;
			}
		}
	}
	&.right,&.align-right{
		float: none;
		@include tab{
			float: right;
			max-width: 50%;
			margin-left: 20px;
			&.remote_video{
				width: 100%;
			}
		}
	}
	&.center,&.align-center{
		@include auto;
		&.remote_video{
			max-width: 66%;
		}	
	}
	.field--name-field-image{
		margin-bottom: .5em !important;
		img{
			width: 100%;
			height: auto;
		}
	}	
	&.view-modemediasmall{
		max-width: 300px;
	}
	&.view-modemediamedium{
		max-width: 500px;
	}
	&.view-modemedialarge{
		max-width: 800px;
	}
	&.remote_video{
		width: 100%;
	}
}//end oc-medi
*/
.field--type-text-with-summary,.field--type-text-long{
	article{
		max-width: 100%;
		@include spacer(1.5);
		&.align-left{
			float: none;
			@include tab{
				float: left;
				max-width: 50%;
				margin-right: 20px;
			}
		}
		&.align-right{
			float: none;
			@include tab{
				float: right;
				max-width: 50%;
				margin-left: 20px;
				
			}
		}
		&.align-center{
			@include auto;
			@include tab{
				max-width: 50%;
			}
		}
		&.remote-video{
			width: 100%;
		}
		.field--name-field-image{
			margin-bottom: .5em !important;
			img{
				width: 100%;
				height: auto;
			}
		}	
	}//end article
}//end long text

