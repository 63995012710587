/* =================
  NAVIGATION
==================== */

/* MAIN MENU
--------------- */
.block-superfishmain{
	&.contextual-region{
		position: static;
	}
	#superfish-main-toggle{
		display: inline-block;
		font-size: 20px;
		text-transform: uppercase;
		color: $black;
		@include safeFlex(row,wrap,null,center);
		height: 50px;
		span{
			@include visuallyHidden;
		}
		&:hover,&:focus{
			color: $hoverLink;
			text-decoration: none;
		}
		&:before{
			font-style: normal;
	    font-variant: normal;
	    text-rendering: auto;
	    -webkit-font-smoothing: antialiased;
			font-family: "Font Awesome 5 Free";
			font-weight: 900;
			content:'\f0c9';
			display: inline-block;
			font-size: 30px;
			position: relative;
			top: -5px;
		}
	}//end superfish-main-toggle
	#superfish-main{
		//desk styles
		&.sf-horizontal{
			@include safeFlex(row,wrap,end,null);
			//first level dropdowns
			li.sf-depth-1{
				& > a{
					color: $black;
					&:hover,&:focus{
						color: $hoverLink;
					}
				}
				& > ul{
					background: rgba(255,255,255,.95);
					border: 1px solid $borderRule;
					top: 100%;
					& > li:not(:last-child){
						border-bottom: 1px solid $borderRule;

					}
				}
			}//end depth 1
			li{
				& > a.is-active{
					color: $white;
					background: $link;
					&:hover,&focus{
						background: $hoverLink;
						color: $white;
					}
				}
			}
		}//end sf-horizontal
	}//end #superfish-main


	/* SUPERFISH MOBILE
	---------------- */
	#superfish-main-accordion{
		&.sf-expanded.sf-main{
			position: absolute;
			left: 0 !important;
			top: 100% !important;
			text-align: left;
			overflow: hidden;
			background: $white;
			border-top: 3px solid $lightBlue;
			border-bottom: 8px solid $black;
		}

		/* ====  global links ====*/
		a{
			padding: 20px 30px;
		}

		//parents, expanders & dropdowns
		&.sf-accordion-with-buttons li{
			& > .sf-accordion-button{
				width: 60px;
				height: 60px;
				float: right;
				text-indent: -300vw;
				position: relative;
				z-index: 1;
				border-left: 1px solid $borderRule;
				//expander toggle
				&:after{
					text-indent: 0;
					position: absolute;
					@include calc(top, "50% - 17px");
					@include calc(left, "50% - 17px");
					margin: 0;
					content: '>';
					background-color: transparent;
					font-size: 36px;
					width: 34px;
					height: 34px;
					text-align: center;
					font-weight: $heavy;
					display: block;
				}
				&:hover{
					background-color: $hoverLink;
					color: $black;
				}
			}//end accordion button

			&.menuparent{
				@include clearFix;
				a{
					&:not(.sf-accordion-button){
						padding-right: 0;
						@include calc(width, "100% - 60px");
					}
				}
			}//end menuparent

			&.active-trail > a,a.is-active{
				background: $link;
				color: $white;
				&.sf-accordion-button{
					border-left-color: $white;
				}
				&:hover{
					background-color: $hoverLink;
					color: $white;
				}
			}
			&.sf-expanded{
				& > .sf-accordion-button{
					&:after{
						@include rotate(90);
					}
				}
				&:not(.active-trail){
					& > .sf-accordion-button{
						border-left: none;
					}
				}
			}
		}//end li



		li.sf-expanded > ul{
			padding: 0 0 10px;
		}


		/* ====  LEVEL 1  ====*/
		li.sf-depth-1{
			// .path-frontpage &,.spanish &,.hmong &,.somali & {
			// 	&.second.item0 {
			// 		display: none;
			// 	}
			// }
			&:not(:last-child){
				border-bottom: 1px solid $borderRule;
			}
			& > a{
				min-height: 60px;
			}
		}//end level 1


		/* ====  LEVEL 2  ====*/
		li.sf-depth-2{

			&:first-of-type.sf-expanded{
				border-top: 0;
			}
			&:last-of-type.sf-expanded{
				border-bottom: 0;
			}
			//inset level2 and those below
			a{
				padding: 15px 30px;
				min-height: 48px;
				&:not(.sf-accordion-button){
					padding: 15px 30px 15px 40px;
				}
				&.sf-accordion-button{
					height: 48px;
					border-left: none;
				}
			}

		}//end level 2


		/* ====  LEVEL 3 & Below ====*/
		li.sf-depth-3{
			//font size for level 3 and those below
			a{
				font-size: 15px;
				&:not(.sf-accordion-button){
					padding-left: 50px;
				}
			}
		}
	}//end superfish-main-accordion block

}//end block superfish main



/* SECONDARY MENU
------------------ */
#block-secondary-menu{
	position: relative;
	display: none;
	@include deskMenu{
		display: block;
	}
	&:before{
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: -100vw;
		width: 200vw;
		height: 1px;
		background: $borderRule;
	}
	.menu--secondary{
		@include safeFlex(row,wrap,end,null);
		@include superMaxWidth{
			padding-right: 40px;
		}
		.path-frontpage &,.spanish &,.hmong &,.somali & {
			li:first-child {
				display: none;
			}
		}
		li{
			width: auto;
			body:not(.right-left) &{
				border-left: 1px solid $borderRule;
				@include superMaxWidth{
					&:last-child{
						border-right: 1px solid $borderRule;
					}
				}
			}
			.right-left &{
				border-right: 1px solid $borderRule;
				@include superMaxWidth{
					&:last-child{
						border-left: 1px solid $borderRule;
					}
				}
			}
			a{
				font-size: 20px;
				padding: 0 25px;
				height: 64px;
				max-width: 230px;
				text-align: center;
				line-height: 1.15;
				@include safeFlex(column,null,center,null);
				&:hover,&:focus{
					text-decoration: underline;
				}
				body:not(.english) &{
					font-size: 16px;
				}
				@include deskMenu{
					padding: 0 12px;
					font-size: 16px;
				}
				@include maxWidth{
					padding: 18px 25px;
					max-width: 260px;
					font-size: 19px;
					body:not(.english) &{
						font-size: 18px;
						max-width: 310px;
						&[href*="guide"]{
							display: none;
						}
					}
				}
				@include superMaxWidth{
					padding: 18px 30px;
					font-size: 21px;
					max-width: none;
					body:not(.english) &{
						font-size: 20px;
					}
				}
				&.is-active{
					color: $white;
					background: $link;
				}
			}
		}
	}//end menu-secondary
}//end secondary


/* BOOKLET CHAPTER NAV
------------------- */
nav.chapter-navigation{
	@include spacer(2);
	@include mobileMenu{
		@include edgeToEdge;
	}
	h2.block-title{
		@include mobileMenu{
			position: relative;
			z-index: 2;
			padding: 1em 20px;
			background: $grey;
			@include spacer(0);
			body:not(.english) &{
				font-size: 22px;
			}
		}
		@include deskMenu{
			display: none;
		}
		a{
			color: $white;
			display: block;
			width: 100%;
			@include mobileMenu{
				&:hover,&:focus{
					color: $transWhite;
				}
			}
		}
		i{
			position: absolute;
			@include calc(top, "50% - 11px");
			right: 20px;
			.right-left &{
				right: auto;
				left: 20px;
			}
		}
	}//end block title
	ul{
		@include listReset;
		li{
			a{
				padding-left: 30px;
				padding-right: 30px;
				display: block;
				color: $darkBlue;
				&:hover{
					color: $darkOrange;
				}
			}
		}
	}//end ul
	.chapter-nav-wrapper{
		display: none;
		padding: 1em 0;
		background: $dirtyWhite;
		@include deskMenu{
			padding: 2em 0;
			display: block !important;
		}
	}
	.chapter-nav-item{
		@include spacer(.5);
		&.active{
			& > a{
				color: $white;
				background: $green;
				padding-top: 6px;
				padding-bottom: 6px;
				&:hover,&:focus{
					color: $white;
					background: $link;
				}
			}
		}
		& > a{
			font-weight: $heavy;
			color: $darkBlack;
			padding-top: 3px;
			padding-bottom: 3px;
			&:hover,&:focus{
				color: darken($hoverLink, 2.5%);
			}
		}
	}//end chapter nav item
	.chapter-sections{
		display: none;
		padding-top: 10px;
		.chapter-section-item{
			padding: 2px 0 2px 20px;
			.right-left &{
				padding: 2px 20px 2px 0;
			}
			a{
				&:hover,&:focus{
					color: darken($hoverLink, 2.5%);
				}
			}
		}
	}//end chapter-sections
}//end chapter-nav


/* SUPPORT NAV
------------------- */
nav.support-navigation{
	@include spacer(2);
	@include mobileMenu{
		@include edgeToEdge;
	}
	h2.block-title{
		position: relative;
		z-index: 2;
		padding: 1em 20px;
		background: $grey;
		@include spacer(0);
		body:not(.english) &{
			font-size: 22px;
		}
		a{
			color: $white;
			display: block;
			width: 100%;
			@include mobileMenu{
				&:hover,&:focus{
					color: $transWhite;
				}
			}
			@include deskMenu{
				cursor: pointer;
				pointer-events: none;
			}
		}
		i{
			position: absolute;
			@include calc(top, "50% - 11px");
			right: 20px;
			.right-left &{
				right: auto;
				left: 20px;
			}
			@include deskMenu{
				display: none;
			}
		}
	}//end block title

	#support-nav-inner{
		display: none;
		padding: 1em 0;
		background: $dirtyWhite;
		@include deskMenu{
			padding: 2em 0;
			display: block !important;
		}
		ul{
			@include listReset;
			li{
				@include spacer(.5);
				&.active{
					& > a{
						color: $white;
						background: $green;
						padding-top: 6px;
						padding-bottom: 6px;
						&:hover,&:focus{
							color: $white;
							background: $link;
						}
					}
					//prevent active child chilren from appearing active
					.active a{
						color: $darkBlack;
						background: none;
						padding-top: 3px;
						padding-bottom: 3px;
						&:hover,&:focus{
							color: $link;
						}
					}
				}
				a{
					padding-left: 30px;
					padding-right: 30px;
					display: block;
					font-weight: $heavy;
					color: $darkBlack;
					padding-top: 3px;
					padding-bottom: 3px;
					&:hover,&:focus{
						color: $link;
					}
				}
				ul.dropdown-menu{
					padding-top: 10px;
					background: none;
					box-shadow: none;
					border: none;
					border-radius: 0;
					position: relative;
					width: 100%;
					top: 0;
					float: none;
					li{
						padding: 2px 0 2px 20px;
						.right-left &{
							padding: 2px 20px 2px 0;
						}
						a{
							color: $link;
							&:hover,&:focus{
								color: $darkBlack;
							}
						}
						&.active > a{
							color: $green;
							&:hover,&:focus{
								color: $darkBlack;
							}
						}
					}
				}
			}//end li
		}//end ul
	}//end support-nav-inner

}//end support-navigation
