/* ========================
   COLORS
========================== */
$white:             #ffffff; 
$dirtyWhite:        #f2f2f2;
$greenWhite:        #edf4f3;
$offWhite:          #e3e8e8;
$lightGrey:         #d8d8d8;
$grey:              #737873;
$darkGrey:          #757575;
$lightOrange:       #fdb957;
$orange:            #c25700;
$darkOrange:        #b23b01;
$green:             #518302;
$teal:              #0d7d9c;
$teal2:             #0C7490;
$lightBlue:         #11a6d0;
$blue:              #000ced;
$darkBlue:          #0c5781;
$black:             #363f3b;
$darkBlack:         #2e2a25;
$midnight:          #000000;
$highlighter:       #f2dc26;
$warning:           #dd241b;

$siteBackground: $white;
$bodyFont: $darkBlack;
$borderRule: $black;
$transWhite       : rgba(255,255,255,0.7);
$transBlack       : rgba(35,35,35,0.7);
$imgOverlay       : rgba(35,35,35,0.4);
$modalBack        : $transBlack;
$modalContentBack : $siteBackground;
$modalHeaderBack  : $darkBlue;
$modalHeader      : $white;
$modalContent     : $bodyFont;

$link: $teal;
$darkLink: #0B7284;
$hoverLink: $orange;
