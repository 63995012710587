/* =================
  SITE ELEMENTS
==================== */
body{
	background-color: $siteBackground;
	& > .overflow-guard{
		overflow-x: hidden;
	}
	&.unpublished{
		background-color: #fff4fa;
	}
}

.region-header .alert{
	max-width: $maxWidthText;
	@include auto;
}

/* GIN TOOLBAR
------------------ */
//fix rem usage of flyout menu sub items from Gin toolbar
#gin-toolbar-bar{
	body.gin--vertical-toolbar &{
		.toolbar-menu-administration{
			& > .toolbar-menu > .menu-item{
				&.menu-item--active-trail,&:hover{
					& > .toolbar-icon{
						&::after,{
							left: calc(50% - 26px);
						}
					}//end menu item
					.toolbar-menu{
						.menu-item{
							.toolbar-icon{
								font-size: 16px;
							}
							&.menu-item--expanded{
								&:before{
									top: .75rem;
								}
							}
						}
					}
				}//end menu item
			}//end top level toolbar menu (icons)
		}//end toolbar administration
	}//end gin vertical toolbar

}//end gin toolbar
//fix responsive preview in gin secondary
.gin-secondary-toolbar{
	#responsive-preview-toolbar-tab{
		button{
			margin-bottom: 0;
			&.responsive-preview-icon{
				font-size: 14px;
			}
		}
		ul.responsive-preview-options{
			button.responsive-preview-device{
				font-size: 12px;
			}
		}
		a.responsive-preview-configure{
			font-size: 10px;
		}
	}//end responsive preview toolbar tab
}//end gin secondary toolbar

/* PRIMARY TABS
----------------------- */
//hide from providers when on content they cannot edit
.role-provider:not(.role-administrator):not(.role-content_administrator):not(.role-client_developer){
	&:not(.page-node-type-services):not(.page-node-type-location):not(.page-node-type-legal-organization):not(.path-user):not(.page-node-type-intake-file){
		nav.tabs{
			display: none;
		}
	}
}


/* SKIP NAV
------------- */
.overflow-guard > a.focusable{
	&:focus{
		position: fixed !important;
		top: 0;
		left: 0;
		z-index: 9999;
		display: block;
		width: 100%;
		background: $white;
		padding: .25em 1em;
	}
}

/* KINTING
--------------- */
.kint{
	position: fixed;
	z-index: 9999;
	top: 200px;
	right: 5%;
	width: 90%;
	max-width: 800px;
	@include calc(max-height, "90% - 200px");
	overflow: scroll;
}


/* BREADCRUMBS
------------------ */
.breadcrumb{
	padding: 30px 0 0;
	@include spacer(1);
	background-color: transparent;
	border-radius: 0;
	ul{
		@include listReset;
		li{
			font-size:: 15px;
			display: inline-block;
		}
	}
}

/* ADMIN TABS
------------------ */
.region-header nav.tabs{
	@include spacer(1.5);
}

/* PREHEADER
--------------- */
section.preheader{
	position: relative;
	max-width: 1800px;
  z-index: 2;
	@include auto;
}//end preheader

/* HEADER
-------------- */
.navbar.navbar-default.container{
	width: 100%;
	max-width: none;
	background-color: $siteBackground;
	border-color: $borderRule;
	border-radius: 0;
	border-top: 0;
	border-left: 0;
	border-right: 0;
	padding-left: 0;
	padding-right: 0;
	@include spacer(0);
	.path-frontpage &{
		@include spacer(0);
	}
	.navbar-header{
		width: 100%;
		max-width: $ultraMaxWidth;
		@include auto;
		float: none;
		@include superMaxWidth{
			padding-left: 20px;
			padding-right: 20px;
		}
	}
	.region-navigation{
		position: relative;
	}
	.branding-block{
		position: relative;
		z-index: 3;
		width: 100%;
		padding: 30px 20px 10px;
		@include deskMenu{
			top: 44px;
			padding: 0 20px 44px;
			max-width: 500px;
			.right-left &{
				padding: 25px 20px 44px;
			}
		}
		@include superMaxWidth{
			padding: 0 20px 44px 0;
			.right-left &{
				padding: 25px 0 44px 20px;
			}
		}
		.logo{
			width: 305px;
			display: block;
			max-width: 390px;
			position: relative;
			z-index: 4;
			margin-bottom: -26px;
			@include smallTab{
				width: 100%;
				margin-bottom: -33px;
			}
			img{
				width: 100%;
				height: auto;
			}
		}
		.navbar-text{
			float: none;
			line-height: 1.15;
			margin: 0;
			font-size: 15px;
			padding: 35px 10px 10px;
			position: relative;
			color: #767676;
			.right-left &{
				max-width: 400px;
				font-size: 14px;
			}
			@include mobileMenu{
				&:before{
					content: '';
					display: block;
					width: 300vw;
					height: 1px;
					background: $borderRule;
					position: absolute;
					left: -75vw;
					top: 0;
				}
			}
			@include smallTab{
				padding: 15px 10px 10px 108px;
			}
			@include deskMenu{
				padding: 5px 0;
				font-size: 18px;
				position: absolute;
				bottom: -20px;
				left: 128px;
				height: 64px;
				@include calc(width, "50vw - 130px");
				@include safeFlex(column,null,center,null);
				.spanish &,.hmong &{
					font-size: 16px;
				}
				.right-left &{
					padding-left: 75px;
					max-width: 350px;
				}
			}
			@include superMaxWidth{
				left: 108px;
				.spanish &,.hmong &{
					font-size: 18px;
				}
				.right-left &{
					max-width: 390px;
				}
			}
		}//end nav-bar text
	}//end branding-block
	.block-superfishmain{
		//hide from providers
		#superfish-main-toggle{
			position: absolute;
			top: 0;
			right: 70px;
			z-index: 4;
		}
		@include deskMenu{
			position: absolute;
			z-index: 4;
			top: 0;
			right: 110px;
			@include calc(width, "100% - 240px");
		}
	}
	.t-search-wrapper{
		position: absolute;
		right: 0;
		top: 0;
		z-index: 6;
	}
}//end header container

/* HIGHLIGHT REGION (used for homepage into banner)
--------------------------- */
.region-highlighted{
	@include ultraMaxWidth{
		@include calc(margin-left, "-50vw + 880px");
		@include calc(margin-right, "-50vw + 880px");
		background: #FFFFFF;
	}
}


/* MAIN CONTAINER
------------------- */
.main-container{
	width: 100%;
	max-width: 1800px;
	padding-left: 20px;
	padding-right: 20px;
	& > .row > *{
		min-height: 0;
	}
	@include calc(min-height, "100vh - 771px");
	.user-logged-in &{
		@include calc(min-height, "100vh - 809px");
	}
	@include smallTab{
		@include calc(min-height, "100vh - 679px");
		.user-logged-in &{
			@include calc(min-height, "100vh - 717px");
		}
	}
	@include tab{
		@include calc(min-height, "100vh - 641px");
		.user-logged-in &{
			@include calc(min-height, "100vh - 679px");
		}
	}
	@include deskMenu{
		@include calc(min-height, "100vh - 664px");
		.user-logged-in &{
			@include calc(min-height, "100vh - 702px");
		}
	}
}

/* CALLOUT BLOCKS (PRE FOOTER) */
#main-content + .region-content{
	@include clearFix;
	& > .block-callout-block{
		@include desk{
			width: 33.33%;
			float: left;
			padding: 0 20px;
			&:first-child:not(:only-child){
				margin-left: -20px;
			}
			&:last-child:not(:only-child){
				margin-right: -20px;
			}
			h2.block-title{
				font-size: 23px;
			}
		}
	}
}


/* FOOTER
------------ */
.footer.container{
	width: 100%;
	max-width: none;
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 3em;
	padding-bottom: 2em;
	background: $green;
	min-height: 375px;
	@include smallTab{
		min-height: 325px;
	}
	@include onlySmall{
		position: relative;
		padding-bottom: 66px;
	}
	@include tab{
		min-height: 316px;
	}
	.region-footer{
		max-width: $maxWidth - 30px;
		@include auto;
		@include clearFix;
	}
}//end footer container


/* BACK TO TOP
----------------- */
//back to table of contents option
.back-anchor{
	display: none;
	position: fixed;
	bottom: 12px;
	left: 20px;
	box-shadow: 3px 3px 5px rgba(35,35,35, 0.35);
	z-index: 9999;
	border-radius: 50%;
	@include tab{
		right: 17px;
		left: auto;
		bottom: 140px;
	}
	a{
		display: block;
		width: 40px;
		height: 40px;
		background: rgba(35,35,35,0.85);
		border-radius: 50%;
		text-indent: -300vw;
		position: relative;
		&:after{
			content: '';
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			@include translate(-50%,-50%);
			width: 20px;
			height: 20px;
			background: url(../images/svg/back-to-top-arrow.svg);
			background-repeat: no-repeat;
			background-position: 0 0;
			background-size: 20px 20px;
		}
	}
}//end back anchor

/* QUICK EXIT
------------------ */
.quick-escape{
	position: fixed;
	z-index: 99999;
	bottom: 12px;
	right: 90px;
	@include tab{
		right: 12px;
	}
	a{
		display: block;
		text-align: center;
		background: $orange;
		color: $white;
		padding: 10px 20px;
		box-shadow: 2px 2px 3px rgba(35,35,35,0.2);
		&:hover,&:focus{
			background: $grey;
			color: $white;
			text-decoration: none;
		}
	}
}

/* LIVE CHAT
---------------- */
#chat-widget-container{
	bottom: -6px !important;
	@include tab{
		bottom: 55px !important;
	}
}


/* MODALS
------------------- */
.modal-element{
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 501;
	background: $modalBack;
	.modal-inner{
		display: none;
		position: absolute;
		top: 50%;
		left: 50%;
		width: 90vw;
		max-width: 640px;
		height: auto;
		max-height: 800px;
		@include translate(-50%,-50%);
		background: $modalContentBack;
		& > .contextual .trigger{
			right: -12px;
		}
	}
	.modal-header{
		position: relative;
		background: $modalHeaderBack;
		h2{
			padding: 1rem 2.5rem;
			font-size: 24px;
			margin-bottom: 0;
			color: $modalHeader;
		}//end block title
		.modal-close{
			border: 1.5px solid $white;
			display: block;
			position: absolute;
			border-radius: 50%;
			top: calc(50% - 11px);
			right: 20px;
			height: 22px;
			width: 22px;
			background: url('../images/svg/close-white.svg') no-repeat;
			background-size: 20px 20px;
			background-position: center;
			&:hover,&:focus{
				opacity: .8;
				background-color: $lightBlue;
				outline: none;
			}
		}
	}//end modal-header
	.modal-content{
		overflow-y: scroll;
		padding: 2.5rem 2.5rem 5rem;
		overflow-x: hidden;
		color: $modalContent;
		input:focus{
			cursor: pointer;
		}
	}
}//end modal-element
