/* =================
  TERMS
==================== */

/* SELF HELP CATEGORIES
-------------------- */
.main-container.term-self-help{
	max-width: $ultraMaxWidth + 160px;
	@include spacer(3);
	.breadcrumb{
		max-width: $ultraMaxWidth - 40px;
		@include auto;
	}
	.self-help-topic-header{
		max-width: $ultraMaxWidth - 40px;
		@include auto;
		@include clearFix;
		.self-help-icon{
			width: 65px;
			height: 65px;
			float: right;
			margin-left: 30px;
			.right-left &{
				float: left;
				margin-left: 0;
				margin-right: 30px;
			}
			@include tab{
				float: left;
				margin-left: 0;
				.right-left &{
					float: right;
					margin-right: 0;
				}
			}
			&.family{
				@include svgBack(/legal-topics/family,65px,65px);
			}
			&.housing{
				@include svgBack(/legal-topics/housing,65px,65px);
			}
			&.car{
				@include svgBack(/legal-topics/car,65px,65px);
			}
			&.money{
				@include svgBack(/legal-topics/money,65px,65px);
			}
			&.work{
				@include svgBack(/legal-topics/work,65px,65px);
			}
			&.seniors{
				@include svgBack(/legal-topics/seniors,65px,65px);
			}
			&.immigration{
				@include svgBack(/legal-topics/immigration,65px,65px);
			}
			&.government{
				@include svgBack(/legal-topics/government,65px,65px);
			}
			&.abuse{
				@include svgBack(/legal-topics/abuse,65px,65px);
			}
			&.disability{
				@include svgBack(/legal-topics/disability,65px,65px);
			}
			&.youth{
				@include svgBack(/legal-topics/youth,65px,65px);
			}
			&.planning{
				@include svgBack(/legal-topics/covid,65px,65px);
			}
			&.criminal{
				@include svgBack(/legal-topics/criminal,65px,65px);
			}
		}//end svg header icon
		h1{
			@include tab{
				margin-left: 85px;
				.right-left &{
					margin-left: 0;
					margin-right: 85px;
				}
			}
		}
		.leadin{
			@include spacer(3);
			@include tab{
				margin-left: 85px;
				.right-left &{
					margin-left: 0;
					margin-right: 85px;
				}
			}
		}
	}//end header

	.legal-topic-content{
		@include maxWidth{
			padding-left: 80px;
			padding-right: 80px;
		}
	}
	.more-resources-wrapper{
		max-width: $ultraMaxWidth + 160px;
		overflow: hidden;
		@include auto;
		@include maxWidth{
			padding-left: 80px;
			padding-right: 80px;
		}
		.more-resources-header{
			@include spacer(2);
			h2.resource-title{
				font-size: 26px;
				color: $darkBlue;
				padding-bottom: .5em;
				border-bottom: 1px solid $borderRule;
				text-align: center;
			}
		}//end more resources header
		.more-resources-content{
			max-width: $superMaxWidth;
			@include auto;
		}
	}//end more resources wrapper
	.more-resources-inner{
		@include safeFlex(row,wrap,null,null);
		@include bleed(30px);
		@include superMaxWidth{
			@include bleed(80px);
		}
		& > div{
			width: 100%;
			padding-left: 15px;
			padding-right: 15px;
			@include spacer(3);
			&:first-child:not(:only-child){
				position: relative;
				padding-bottom: 1.5em;
				&:after{
					content: '';
					display: block;
					max-width: 1760px;
					height: 1px;
					background: $borderRule;
					position: absolute;
					bottom: 0;
					left: 50%;
					@include translateX(-50%);
					@include calc(width, "100vw - 40px");
				}
			}
			&.inner-topic{
				&:nth-child(4){
					padding-top: 2.5em;
					position: relative;
					&:before{
						content: '';
						display: block;
						height: 1px;
						max-width: 1760px;
						background: $borderRule;
						position: absolute;
						top: 0;
						left: -80px;
						@include calc(width, "100vw + 320px");
					}
				}
				&:nth-child(5){
					@include tab{
						padding-top: 2.5em;
					}
				}
			}
			@include tab{
				&:not(.topics-booklets){
					width: 50%;
				}
			}
			@include superMaxWidth{
				padding-left: 40px;
				padding-right: 40px;
			}
		}//end > div
	}//end more resources inner
	.lawhelp-guide-link{
		font-size: 24px;
		font-weight: bold;
		max-width: $maxWidthText;
		margin: 0 auto;
		//shows english can't find what you are looking for text and hides multilingual text.
		.english-only{
			display: block;
		}
		.multi-only{
			display: none;
		}
	}
	// shows multilingual can't find what you are looking for text and hides english text.
	.spanish,
	.hmong,
	.somali{
		.lawhelp-guide-link{
			.english-only{
				display: none !important;
			}
			.multi-only{
				display: block !important;
			}
		}
	}

}//end self help term
