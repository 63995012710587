/* =================
  MISCELLANEOUS
==================== */

@import '_views.scss';

/* GENERAL MISC
------------------ */
.inline-label{
	font-weight: $heavy;
	& + *{
		display: inline;
	}
}

//info tip
.tip-info{
	position: relative;
	display: inline;
	i{
		font-size: 16px;
	}
	.tip-info-text{
		display: none;
		position: absolute;
		bottom: 100%;
		right: auto;
		background: $dirtyWhite;
		border: 1px solid $borderRule;
		box-shadow: 3px 3px 8px $imgOverlay;
		width: 100%;
		width: 250px;
		font-size: 16px;
		font-weight: $regular;
		padding: .5em 1em;
		line-height: 1.4;
		@include deskMenu{
			right: 0;
		}
		.right-left &{
			left: 0;
			right: inherit;
			@include tab{
				left: -58px;
			}
		}
		@include tab{
			width: 350px;
		}
	}
}//end tip-info


/* DOCUMENTS
--------------------- */
.field--name-field-document,.views-field-intake-file{
	.file{
		font-size: inherit;
		display: inline-block;
		margin: 0;
		width: auto;
		font-weight: 400;
		line-height: 1;
		&:hover,&:focus{
			.file-icon{
				@include opacity(.7);
			}
		}
		& > span{
			display: inline;
			border-top: none;
			border-bottom: none;
			padding: 0;
			background: none;
			&:first-child{
				border-left: none;
			}
			&:last-child{
				border-right: none;
			}
		}
		.file-link a{
			color: $link;
			&:hover,&:focus{
				color: $hoverLink;
			}
		}
		.file-icon{
			margin-right: .35em;
			.right-left &{
				margin-right: 0;
				margin-left: .35em;
			}
			.glyphicon-file{
				&:before{
					content: '';
					display: block;
					width: 18px;
					height: 18px;
					background: url(../images/svg/file-icon.svg) no-repeat center;
					position: relative;
					top: -2px;
				}
			}
		}
		.file-size{
			@include visuallyHidden;
		}
	}//end file icon
}//end document


/* CONSTRAINED PAGES
----------------- */
.main-container.page-constrained{
	max-width: $maxWidth;
	@include auto;
}//end page-constrained


/* USER LOGIN PAGE
-------------------- */
//hide menus so providers are restricted to their section
.path-user.role-anonymous{
	#block-mainnavigation,#block-secondary-menu .menu--secondary,.block-search-form-block,#block-bootsass-child-footer{
		display: none;
	}
	#block-secondary-menu{
		min-height: 64px;
		display: block;
	}
}

/* SEARCH PAGE
------------------ */
.path-site-search{

	//move main menu over because header search block is not used
	.navbar.navbar-default.container .block-superfishmain{
		#superfish-main-toggle{
			right: 20px;
		}
		@include deskMenu{
			right: 0;
		}
	}

	.main-container{
		padding: 0 20px !important;
		max-width: $maxWidth;
		position: relative;

		.site-search{
			@include safeFlex(row,wrap,null,stretch);

			.view-filters{
				margin-top: 2em;
				display: block;
				width: 100%;
				position: relative;
				z-index: 1;

				#views-exposed-form-site-search-site-search-page{
					padding: 50px 0;
					background: none;
					max-width: 590px;
					position: relative;
					@include auto;
					&:after{
						@include fullBack($darkBlue);
					}
					.control-label{
						@include visuallyHidden;
					}
					.form-item-site-search-api-fulltext{
						margin-bottom: 0;
						width: 100%;
						input{
							background: $siteBackground;
							color: $grey;
							padding-left: 20px;
							padding-right: 20px;
							font-style: italic;
							font-size: 24px;
							height: auto;
							width: 100%;
							@include placeholder;
							//prevent yellow chrome autofill inset
							&:-webkit-autofill{
								-webkit-box-shadow: 0 0 0 1000px $white inset;
							}
							&:focus{
								@include placeholderFocus;
							}
						}
					}
					.form-actions{
						position: absolute;
						top: 70px;
						right: 10px;
						@include tab{
							top: 65px;
						}
						button{
							padding: 0;
							height: 22px;
							width: 22px;
							background: url(../images/svg/search-toggle-black.svg);
							background-repeat: no-repeat;
							background-position: 0 0;
							background-size: 22px 22px;
							text-indent: -900vw;
							@include opacity(.8);
							&:hover,&:active,&:focus{
								@include opacity(.4);
							}
							@include tab{
								height: 26px;
								width: 26px;
								background: url(../images/svg/search-toggle-black.svg);
								background-repeat: no-repeat;
								background-position: 0 0;
								background-size: 26px 26px;
							}
						}
					}
				}//end form
			}//end block search form

			.search-required,.no-results{
				padding: 3em;
				width: 100%;
				text-align: center;
				max-width: $maxWidthText;
				@include auto;
				@include maxWidth{
					font-size: 20px;
				}
			}

			.site-search-header{
				width: 100%;
				text-align: center;
				@include spacer(2);
				.results-header{
					padding: 2em 0 .5em;
					width: 100%;
					max-width: $maxWidth;
					border-bottom: 1px solid $borderRule;
					@include spacer(0);
					@include auto;
					span{
						margin-left: 1em;
						font-style: italic;
						font-weight: $regular;
						font-size: 26px;
						display: inline-block;
					}
				}//end results view header
				a{
					padding: .5em 1.5em;
					font-size: 18px;
				}
			}//end view-header

			.search-main-content{
				width: 100%;
				position: relative;
				@include deskMenu{
					@include calc(width, "100% - 360px");
					padding-right: 20px;
					padding-bottom: 88px;
				}

				.view-content{
					max-width: $maxWidthText;
					@include auto;
					@include spacer(3);
				}

				.views-row{
					&:not(:last-child){
						padding-bottom: 1.5em;
						border-bottom: 1px solid $borderRule;
						@include spacer(1.5);
					}
					.views-field{
						@include spacer(.5);
						span.views-label{
							font-weight: $heavy;
							margin-right: .5em;
						}
						&.views-field-title{
							font-size: 22px;
						}
					}
					@extend %viewsResourceRow;

					.topic-icon{
						&.page{
							@include svgBack(pages,30px,30px);
							@include tab{
								background-size: 42px 42px;
							}
						}
						&.service{
							@include svgBack(services,30px,30px);
							@include tab{
								background-size: 42px 42px;
							}
						}
					}
				}//end views row

				//results pager
				.pager-wrapper{
					@include resultsPager;
					max-width: $maxWidthText + 40px;
					@include auto;
					@include deskMenu{
						position: absolute;
						bottom: 0;
						left: 0;
						width: 100%;
						z-index: 10;
					}
					.page-counter{
						@include tab{
							padding: 1.5em 0;
							width: 60%;
						}
					}
					.results-pager{
						@include tab{
							width: 40%;
						}
					}
				}//end pager wrapper

				.view-empty{
					padding-top: 2em;
					max-width: $maxWidthText;
					@include auto;
					@include spacer(3);
					div{
						font-weight: $heavy;
						font-size: 22px;
						@include spacer(.5);
					}
				}//end views empty

			}//end search main content

			.search-extras{
				width: 100%;
				@include mobileMenu{
					@include edgeToEdge;
					@include calc(width, "100% + 40px");
				}
				@include deskMenu{
					width: 360px;
					position: relative;
					z-index: 2;
					padding-left: 20px;
					position: relative;
					background: $dirtyWhite;
				}
				// .resource-results{
				// 	padding: 40px 20px 30px;
				// 	background: $dirtyWhite;
				// 	@include deskMenu{

				// 	}
				// 	.view-header{
				// 		@include spacer(1.5);
				// 		h2{
				// 			font-size: 24px;
				// 			line-height: 1.15;
				// 		}
				// 	}
				// 	.views-row{
				// 		@include spacer(1);
				// 		.views-field-title{
				// 			strong{
				// 				font-weight: $regular;
				// 			}
				// 		}
				// 	}
				// }//end resource results
				.search-callout{
					padding: 40px 20px;
					@include deskMenu{
						padding: 40px 20px 160px;
					}
					a{
						color: $darkBlue;
						&:hover{
							color: $darkOrange;
						}
					}
					p:first-child{
						strong{
							font-size: 24px;
							line-height: 1.15;
						}
					}
				}
			}//end search extras
		}//site-search
	}//layout content

	//right to left search page
	&.right-left{
		.main-container{
			.site-search{
				.block-search-form-block{
					.search-form-wrapper{
						left: auto;
						right: 0;
					}
					#views-exposed-form-site-search-site-search-page{
						.form-actions{
							input{
								left: 18px;
								right: auto;
							}
						}
					}
				}//end block search form
				.site-search-header{
					.results-header{
						span{
							margin-left: 0;
							margin-right: 1em;
						}
					}
				}
				.search-main-content{
					@include deskMenu{
						padding-right: 0;
						padding-left: 20px;
					}
					.pager-wrapper{
						.page-counter{
							@include tab{
								text-align: right;
							}
						}
						.results-pager{
							.pager-nav{
								@include tab{
									text-align: left;
								}
								.pagination{
									padding-right: 0;
								}
							}
						}
					}
				}//end search main content
				.search-extras{
					@include deskMenu{
						padding-left: 0;
						padding-right: 20px;
					}
					.resource-results{
						@include deskMenu{
							&:after{
								left: auto;
								right: 0;
							}
						}
					}
					.search-callout{
						@include deskMenu{
							&:after{
								left: auto;
								right: 0;
							}
						}
					}
				}//end search extras
			}//end site-search
		}//end main container
	}//end right left
}//end search page


/* HOME PAGE
----------------- */
.page-node-type-landing-page{
	.navbar{
		margin-bottom: 0px;
	}
	.main-container > .row > .col-sm-12 > .region-content{
		max-width: $maxWidth - 80px;
		@include auto;
	}

	.landing-page-top{

		@include tab{
			@include safeFlex(row, wrap, justify, inherit);
		}
		.landing-page-left{

			@include tab{
				flex: 1;
				padding-top: 30px;
				padding-bottom: 30px;
			}
		}
		.landing-page-right{

			@include tab{
				flex: 1;
				margin-right: -14%;
			}
		}
	}
	.landing-page-body{
		margin: 0 auto;
		max-width: $maxWidthText;
	}
	.landing-page-widgets{
		margin: 0 auto;
		max-width: $maxWidth;
	}
	&.path-frontpage{
		.field--name-oc-widgets > .field--item:first-child .paragraphs-item-4621{
			margin-top: -2em;
			margin-bottom: -2em;
		}
	}
}//end home page


/* FIND LEGAL HELP
---------------------- */
.legal-help-crumbs{
	max-width: 980px;
	@include auto;
}
