/* =================
  VIEWS
==================== */

%loonAccount{
	position: relative;
	padding: 0 0 2em;
	@include safeFlex(row,wrap,null,null);
	@include edgeToEdge;
	@include calc(width, "100% + 40px");
	&:after{
		@include fullBack($darkBlue);
	}
	.account-services{
		width: 100%;
		@include deskMenu{
			width: 280px;
			@include safeFlexOrder(2);
		}
		.path-loon:not(.user-logged-in) &{
			display: none;
		}
		.account-label{
			background: $green;
			color: $white;
			padding: 1em 30px;
			font-size: 20px;
			@include spacer(1);
			i{
				font-size: 22px;
				margin-right: 1em;
			}
			a{
				color: $white;
				margin-left: 1em;
				@include deskMenu{
					display: none;
				}
				&:hover,&:focus{
					color: $transWhite;
				}
			}
		}
		.account-services-inner{
			padding-left: 20px;
			padding-right: 20px;
			display: none;
			@include deskMenu{
				padding-left: 0;
				padding-right: 0;
				display: block !important;
			}
			a{
				padding-left: 35px;
				position: relative;
				color: $white;
				font-size: 16px;
				display: block;
				&:before{
					content: '•';
					position: absolute;
					left: 8px;
					top: 0;
				}
				&:hover,&:focus{
					color: $transWhite;
				}
				&.is-active{
					color: $transWhite;
					&:hover,&:focus{
						color: $white;
					}
				}
			}
		} //end account services inner
	}//end account services
	.loon-inner-wrapper{
		width: 100%;
		padding: 2em 20px 0;
		@include safeFlex(row,null,null,center);
		@include deskMenu{
			@include safeFlexOrder(1);
			@include calc(width, "100% - 280px");
		}
		.loon-logo{
			width: 225px;
			height: 154px;
			background: url(../images/scanned-loon-ps.png) no-repeat;
			background-size: contain;
			background-position: center;
			@include onlySmall{
				display: none;
			}
		}//end loon logo
		.loon-text{
			color: $white;
			background-color: $darkBlue;
			position: relative;
			h1{
				color: $white;
				background-color: $darkBlue;
				font-size: 56px;
				@include spacer(.25);
				body:not(.english) &{
					font-size: 44px;
					@include tab{
						font-size: 56px;
					}
				}
			}
			@include tab{
				&:not(:first-child){
					padding-left: 40px;
					@include calc(width, "100% - 225px");
				}
			}
		}//end loon text
		.staff-directory-link{
			display: block;
			position: absolute;
			top: 18px;
			right: 0;
			@include deskMenu{
				margin-top: 1.5em;
				position: static;
			}
			a{
				background: $green;
				color: $white;
				padding: .5em 20px;
				font-size: 16px;
				text-decoration: none;
				&:hover,&:focus{
					background: $grey;
				}
			}
		}
	}//end loon inner wrapper
}//end loon account placholder


%viewsMoreWrapper{
	.views-more-inner{
		display: none;
	}//end views more inner
	.views-more-button.button-outline{
		display: block;
		width: 100%;
		padding: .75em 1em;
		text-transform: uppercase;
		font-weight: 400;
		@include spacer(0);
		@include auto;
		@include smallTab{
			max-width: 300px;
		}
	}
}//end views more wrapper

/* GLOBAL VIEWS
---------------- */
.views-exposed-form{
	//reset button
	button[value="Reset"]{
		background: $darkBlue;
		&:hover,&:focus{
			background: $green;
		}
	}
}
.views-element-container.form-group{
	@include spacer(0);
}

/* MAIN LOON VIEW (Provider Portal)
---------------- */
.view-loon-services{
	max-width: 1000px;
	@include auto;
	@include maxWidth{
		max-width: $maxWidth;
	}
	.loon-account-block{
		@extend %loonAccount;
	}//end loon account block


	.views-exposed-form.bef-exposed-form{
		position: relative;
		padding: 2.5em 0;
		&:after{
			@include fullBack($greenWhite);
		}
		.filter-title{
			font-size: 28px;
			font-weight: $regular;
			padding-bottom: .5em;
			color: $midnight;
			border-bottom: 1px solid $borderRule;
			@include spacer(1);
			.tip-info{
				position: relative;
				top: -10px;
				body:not(.english) &{
					display: none;
				}
			}
		}
		.filters-description{
			@include spacer(1);
			.translation-help{
				font-weight: $heavy;
				body.english &{
					display: none;
				}
			}
		}
		.form-inline{
			.form-item:not(.form-type-checkbox){
				display: block;
				@include spacer(0);
				label{
					display: block;
					@include spacer(.5);
				}
				input{
					width: 100%;
					font-size: 20px;
					@include spacer(.25);
					&.form-text{
						border: 1px solid $borderRule;
						border-radius: 0;
						min-height: 48px;
						body:not(.english) &{
							@include tab{
								font-size: 18px;
							}
							@include deskMenu{
								font-size: 20px;
							}
						}
					}
					&#edit-search-api-fulltext{
						@include placeholder;
						&:focus{
							@include placeholderFocus;
						}
					}
				}
				.select2-container{
					max-width: none;
					@include spacer(.25);
					.select2-selection__rendered{
						font-size: 20px;
						body:not(.english) &{
							@include tab{
								font-size: 18px;
							}
							@include deskMenu{
								font-size: 20px;
							}
						}
					}
					.select2-selection--multiple{
						padding: 3px 0 0;
						min-height: 48px;
						input{
							@include spacer(0);
							max-width: 100%;
							body:not(.english) &{
								@include tab{
									font-size: 18px;
								}
								@include deskMenu{
									font-size: 20px;
								}
							}
						}
					}
					.select2-selection--single{
						padding: 5px 0 0;
						min-height: 48px;
						.select2-selection__placeholder{
							color: $bodyFont;
							@include opacity(.4);
							&:focus{
								@include opacity(.2);
							}
						}
						&[aria-expanded="true"]{
							.select2-selection__placeholder{
								@include opacity(.2);
							}
						}
					}
				}//end select2 container
			}//end not checkbox items
			.county-lookup{
				font-size: 16px;
				padding-left: .5em;
			}

			.form-type-checkbox{
				margin-bottom: 0;
				.custom-element:before{
					background: $white;
				}
				input:hover + span:before,input:focus + span:before{
					background: rgba(13,125,156,0.3);
				}
				input:checked + span:before{
					background: $green;
				}
				input:checked:hover + span:before{
					background: rgba(81,131,2,0.6);
				}
			}

			.tip-info{
				.tip-info-text{
					@include deskMenu{
						&#main-search-tip{
							right: -36px;
						}
						&#fpg-tip{
							right: -236px;
						}
					}
					.right-left &{
						&#fpg-tip{
							right: -100px;
						}
						&#eligibility-tip{
							@include deskMenu{
								left: -30px;
							}
						}
					}
				}
			}//end tip info

			.main-filter-wrapper{
				@include clearFix;
				@include bleed(20px);
				@include safeFlex(row,wrap,null,null);
				//legal topics heirarchical filter
				.main-filter-full{
					width: 100%;
					position: relative;
					padding: 0 10px;
					@include spacer(2);
					.main-filter-full-inner{
						.control-label.bottom-space{
							margin-bottom: .5em;
						}
						.tip-info{
							body.english &{
								display: none;
							}
						}
						.shs-widget-container{
							position: relative;
							margin-right: 5px;
							@include spacer(.5);
							max-width: 100%;
						}
						select{
							@include browserWipe;
							border-radius: 0;
							border: 1px solid $borderRule;
							padding: 8px 40px 8px 12px;
							color: $grey;
							max-width: 100%;
							body:not(.english) &{
								@include tab{
									font-size: 18px;
								}
								@include deskMenu{
									font-size: 20px;
								}
							}
						}
					}//end main-filter-full-inner
				}//end main filter full
				.main-filter{
					width: 100%;
					padding-left: 10px;
					padding-right: 10px;
					@include spacer(2);
					@include tab{
						width: 33.333%;
					}
				}
				.actions{
					padding-left: 10px;
					padding-right: 10px;
					width: 100%;
					text-align: right;
				}
			}//end main filer wrapper

			.service-type.new-services-filter{
				padding-left: 10px;
				padding-right: 10px;
				width: 100%;
				@include spacer(2);
				@include tab{
					width: 33.333%;
				}
				label.bottom-space{
					@include spacer(.5);
				}
			}

			.additional-filters-wrapper{
				.additional-label{
					@include spacer(1);
					span{
						font-size: 24px;
						margin-right: .5em;
					}
				}
				.additional-drawer{
					@include bleed(20px);
					@include safeFlex(row,wrap,null,null);
					.additional-filter{
						padding-left: 10px;
						padding-right: 10px;
						@include spacer(2);
						label.bottom-space{
							@include spacer(.5);
						}
					}
					.fpg{
						.tip-info{
							top: -4px;
						}
					}
					.form-item-fpg-1{
						width: 160px;
					}
					.form-item-age-range{
						width: 185px;
					}
					.form-item-service-type{
						width: 275px;
					}
					.special-reqs{
						label{
							display: block;
							.tip-info{
								top: -4px;
							}
						}
					}
				}//end additional-drawer
			}//end additional-filter-wrapper

			//buttons
			.actions button{
				font-weight: $regular;
				width: 160px;
				padding: .75em 1em;
				body:not(.english) &{
					width: 200px;
				}
				@include tab{
					width: 210px;
					body:not(.english) &{
						width: 230px;
					}
				}
				&:first-child{
					margin-right: 16px;
					.right-left &{
						margin-right: 0;
						margin-left: 16px;
					}
				}
			}
		}//end form inline
	}//end views exposed form

	//ajax loader
	#ajax-loader{
		display: none;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(255,255,255,0.8);
		img{
			display: block;
			width: 64px;
			position: absolute;
			top: 50%;
			left: 50%;
			@include translate(-50%,-50%);
		}
	}

	/* RESULTS HEADER
	------------------ */
	.results-header{
		padding: 1em 0;
		position: relative;
		margin-top: -1em;
		@include spacer(2);
		&:after{
			@include fullBack($lightGrey);
		}
	}//end results view header
	.print-results{
		display: none;
	}

	/* LOON CONTENT
	----------------- */
	.loon-view-content{
		padding-top: 2em;
		@include edgeToEdge;
	}
	.views-row.service-row{
		width: 100%;
		padding: 2em 20px 2.5em;
		@include clearFix;
		.tip-info{
			a.trigger{
				color: darken($link, 2.5%);
				&:hover,&:focus{
					color: darken($hoverLink, 2.5%);
				}
				&.button-outline{
					&:hover,&:focus{
						color: $white;
					}
				}
				&.active{
					color: $white;
				}
			}
		}
		&:nth-child(even){
			background: $dirtyWhite;
			a:not(.button){
				color: darken($link, 2.5%);
				&:hover,&:focus{
					color: darken($hoverLink, 2.5%);
				}
				&.button-outline{
					&:hover,&:focus{
						color: $white;
					}
				}
				&.active{
					color: $white;
				}
			}
		}
		@include mobileMenu{
			@include spacer(1);
			@include safeFlex(row,wrap,null,null);
		}
		@include deskMenu{
			@include spacer(3);
		}
		.views-field-label{
			&:not(.inline-label){
				font-weight: $heavy;
				display: inline-block;
				padding-bottom: 5px;
				border-bottom: 1px solid $borderRule;
				@include spacer(.5);
			}
		}
	}//end views row

	.service-info{
		@include mobileMenu{
			width: 100%;
			border-top: 1px solid $borderRule;
			border-bottom: 1px solid $borderRule;
			@include safeFlexOrder(2);
			@include spacer(1.5);
		}
		@include deskMenu{
			float: right;
			width: 260px;
			.right-left &{
				float: left;
			}
		}
		.service-info-inner{
			padding: 1.5em 0;
			@include mobileMenu{
				padding-left: 19px;
			}
			@include deskMenu{
				padding: 1.5em 0 1.5em 40px;
				.right-left &{
					padding: 1.5em 40px 1.5em 0;
				}
			}
			&.is-clinic{
				padding-bottom: 80px;
			}
			& > div{
				@include spacer(.25);
			}
			.tip-info{
				top: -4px;
				margin-left: -19px;
			}
			.views-field-special-requirements{
				span{
					display: block;
				}
			}
		}
		.clinic-badge{
			background: $green;
			width: 100px;
			height: 100px;
			border-radius: 50%;
			margin-left: 40px;
			position: relative;
			top: -50px;
			@include spacer(-1.5);
			@include safeFlex(column,null,center,center);
			.right-left &{
				margin-left: 0;
				margin-right: 40px;
			}
			body:not(.english) &{
				width: 125px;
				height: 125px;
				font-size: 14px;
			}
			span{
				color: $white;
				text-transform: uppercase;
				font-weight: $heavy;
				text-align: center;
			}
		}
	}//end service info

	.service-text{
		position: relative;
		a{
			word-break: break-word;
		}
		@include mobileMenu{
			@include safeFlexOrder(1);
		}
		@include deskMenu{
			padding-right: 40px;
			@include calc(width, "100% - 260px");
			border-right: 1px solid $borderRule;
		}
		h2.new-title{
			@include calc(width, "100% - 80px");
		}
		.views-field-new{
			position: absolute;
			top: -10px;
			right: 0;
			@include deskMenu{
				right: 20px;
			}
			.new-listing{
		    background: $darkBlue;
		    font-weight: $heavy;
		    text-transform: uppercase;
		    color: $white;
		    border-radius: 50%;
		    width: 70px;
		    height: 70px;
		    line-height: 70px;
		    text-align: center;
				font-size: 12px;
				&.updated{
					background: $orange;
				}
			}
			& + .service-desc,& + .service-application{
				@include calc(width, "100% - 80px");
			}
		}
		.service-application,.service-desc{
			@include spacer(1.5);
		}
		.service-areas{
			@include spacer(1.5);
			.areas-list{
				display: inline;
			}
		}//end service areas

		.edit-service{
			@include spacer(1.5);
		}
	}//end service text

	.service-card-wrapper{
		@include mobileMenu{
			width: 100%;
			@include safeFlexOrder(3);
		}
		@include deskMenu{
			padding-right: 40px;
			@include calc(width, "100% - 260px");
		}
		.service-toggle-wrapper{
			@include safeFlex(row,wrap,null,null);
			@include deskMenu{
				@include bleed(6px);
			}
			.service-toggle{
				font-size: 16px;
				padding: .75em .5em;
				min-width: 210px;
				margin-bottom: -2px;
				display: block;
				width: 100%;
				border-color: $borderRule;
				&:hover,&:focus{
					border-color: $green;
					text-decoration: underline;
				}
				.hmong &,.somali &{
					font-size: 15px;
				}
				@include deskMenu{
					@include calc(width, "33.33% - 6px");
					margin-left: 3px;
					margin-right: 3px;
					max-width: 225px;
					font-size: 15px;
					white-space: normal;
					.somali &,.hmong &{
						max-width: none;
					}
				}

				@include maxWidth{
					font-size: 16px;
				}
				&.active{
					background: $darkBlue;
					color: $white;
					@include onlySmall{
						@include safeFlexOrder(3);
					}
					&:hover,&:focus{
						background: $green;
					}
				}
			}
		}//end service toggle wrapper
		.service-card{
			display: none;
			padding: 2em;
			position: relative;
			border: 2px solid $borderRule;
			@include clearFix;
		}
		// service and staff notes card
		.views-field-staff-notes{
			@include spacer(1.5);
		}

		//legal topics card
		.views-field-legal-resources-topics{
			ul{
				@include listReset;
				// li{
				// 	position: relative;
				// 	padding: 3px 0 3px 20px;
				// 	@include spacer(1.5);
				// 	&:before{
				// 		content: '';
				// 		display: inline-block;
				// 		width: 10px;
				// 		height: 10px;
				// 		background: $lightBlue;
				// 		position: absolute;
				// 		top: 10px;
				// 		left: 0;
				// 	}
				// }
				.term-tree-list{
					@include topicsTree;
				}//end term tree list
			}
		}//end views field resources legal topics

		//contact info
		.contact-hours{
			@include smallTab{
				&.clinic > ul{
					width: 50%;
					padding-right: 20px;
					float: left;
					& > li{
						margin-bottom: 40px;
						&:only-child{
							.location-name{
								display: none;
							}
						}
					}
				}
				&:not(.clinic){
					& > ul > li{
						@include clearFix;
						margin-bottom: 40px;
						&:only-child{
							.location-name{
								display: none;
							}
						}
						&:not(:only-child){
							.location-schedule{
								margin-top: 5px;
							}
						}
					}
					.location-wrapper:not(:only-child){
						width: 50%;
						padding-right: 20px;
						float: left;
					}
					.location-schedule{
						width: 50%;
						padding-left: 20px;
						float: left;
					}
				}
			}//end smalltab
			& > ul{
				@include listReset;
			}

			.location-name{
				font-size: 22px;
				font-weight: $heavy;
			}
			.field{
				@include spacer(0);
			}
			.location-wrapper > .field{
				@include spacer(1.5);
			}

			.address{
				.country{
					display: none;
				}
			}
			.field--name-phone{
				.field--item{
					.field--name-department{
						font-weight: $heavy;
						display: inline-block;
						&:after{
							content: ":";
							margin-right: .5em;
						}
					}
					a{
						display: inline-block;
						margin-right: .5em;
					}
					.field--name-extension{
						display: inline;
						.field--label{
							font-weight: 400;
							padding-right: 0;
						}
					}
				}
			}//end phone
			.field--name-contacts{
				.field--item{
					& > li:not(:last-child){
						@include spacer(.5);
					}
				}
			}//end location contact
		}//end contact info

		//location schedule
		.location-schedule{
			.field{
				@include spacer(0);
			}
			@include smallTab{
				&:not(:only-child){
					width: 50%;
					padding-left: 20px;
					float: left;
				}
			}
			& > .field{
				@include spacer(1.5);
				& > .field--item{
					@include spacer(.5);
				}
			}
		}//end location-schedule

		//clinic schedule
		.views-field.schedule-info{
			.field{
				@include spacer(0);
			}
			@include smallTab{
				&:not(:only-child){
					width: 50%;
					padding-left: 20px;
					float: left;
				}
			}
			& > .views-field{
				@include spacer(1.5);
			}
			//regular schedule
			.views-field-schedule{
				ul{
					@include listReset;
				}
			}
			//clinic hours
			.views-field-clinic-schedule{
				ul{
					@include listReset;
					li{
						@include spacer(.5);
					}
				}
			}//end clinic hours
			//holiday schedule
			.views-field-holiday-schedule{
				& > ul{
					@include listReset;
					li{
						@include spacer(.5);
					}
				}
			}//end views field holiday schedule
		}//end schedule info
		.views-field-email{
			clear: both;
		}
	}//end service card wrapper


	//results pager
	.pager-wrapper{
		@include resultsPager;
	}//end pager wrapper

	//our services no results
	.loon-view-empty{
		padding-top: 2em;
		@include spacer(3);
	}//end views empty

	//can't find what you are looking for text
	.lawhelp-guide-link{
		font-size: 24px;
		font-weight: bold;
		max-width: $maxWidthText;
		margin: 0 auto;
		margin-bottom: 2em;
		//shows english can't find what you are looking for text and hides multilingual text.
		.english-only{
			display: block;
		}
		.multi-only{
			display: none;
		}
	}
	//end can't find what you are looking for

	/* PROVIDERS AND CLINICS */
	&.view-display-id-providers_clinics,&.view-display-id-new_services{
		//hide location contacts
		.field--name-contacts{
			display: none;
		}
	}//end providers and clinics
	&.view-display-id-all_services,&.view-display-id-our_services {
		.service-card-wrapper{
			.service-toggle-wrapper{
				.service-toggle{
					min-width: 205px;
					@include deskMenu{
						max-width: none;
						width: 100%;
					}
					@include maxWidth{
						@include calc(width, "25% - 6px");
					}
				}
			}

		}
	}
} //end loon zoom

// shows multilingual can't find what you are looking for text and hides english text.
.spanish,
.hmong,
.somali{
	.lawhelp-guide-link{
		.english-only{
			display: none !important;
		}
		.multi-only{
			display: block !important;
		}
	}
}

/* OUR LOCATIONS
------------------- */
.view-location.view-display-id-our_locations{
	max-width: 1000px;
	@include auto;
	@include maxWidth{
		max-width: $maxWidth;
	}
	.loon-account-block{
		@extend %loonAccount;
		.view-display-id-block_5{
			.views-field-nothing > .field-content > a:first-child{
				display: none;
			}
		}
	}
	.views-row{
		width: 100%;
		max-width: $maxWidthText;
		@include auto;
		&:not(:last-child){
			padding-bottom: 1.5em;
			border-bottom: 1px solid $borderRule;
			@include spacer(1.5);
		}
		.views-field-body{
			@include spacer(1.5);
		}
	}
	.loon-view-content,.loon-view-empty{
		padding-top: 2em;
		@include spacer(3);
	}
}//end our locations


%viewsResourceRow{
	position: relative;
	padding-left: 50px;
	@include spacer(1.5);
	min-height: 30px;
	.right-left &{
		padding-left: 0;
		padding-right: 50px;
	}
	@include tab{
		padding-left: 70px;
		min-height: 42px;
		.right-left &{
			padding-left: 0;
			padding-right: 70px;
		}
	}
	&:last-child{
		@include spacer(1.5);
	}
	.topic-icon{
		position: absolute;
		top: 6px;
		left: 0;
		width: 30px;
		height: 30px;
		.right-left &{
			left: auto;
			right: 0;
		}
		@include tab{
			top: 4px;
			width: 42px;
			height: 42px;
		}
		&.checklist{
			background-image: url(../images/checkmark.png);
			background-repeat: no-repeat;
			background-position: 0 0;
			background-size: 30px 30px;
			@include tab{
				background-size: 42px 42px;
			}
		}
		&.fact-sheet{
			@include svgBack(/legal-topics/fact-sheet,30px,30px);
			@include tab{
				background-size: 42px 42px;
			}
		}
		&.booklet{
			@include svgBack(/legal-topics/booklet,30px,30px);
			@include tab{
				background-size: 42px 42px;
			}
		}
		&.form{
			@include svgBack(/legal-topics/form,30px,30px);
			@include tab{
				background-size: 42px 42px;
			}
		}
		&.q-a{
			@include svgBack(/legal-topics/q-a,30px,30px);
			@include tab{
				background-size: 42px 42px;
			}
		}
		&.video{
			@include svgBack(/legal-topics/video,30px,30px);
			@include tab{
				background-size: 42px 42px;
			}
		}
		&.website{
			@include svgBack(/legal-topics/website,30px,30px);
			@include tab{
				background-size: 42px 42px;
			}
		}
	}//end topic icon
	.views-field:not(:last-child){
		@include spacer(.5);
	}
	.views-field-title{
		font-size: 22px;
	}
}//end views resource row


/* PROVIDERS SERVICE INTAKE FILES
--------------------------- */
.view-intake-file.view-display-id-intake_files{
	max-width: 1000px;
	@include auto;
	@include maxWidth{
		max-width: $maxWidth;
	}
	.loon-account-block{
		@extend %loonAccount;
	}
	.loon-view-content,.loon-view-empty{
		padding-top: 2em;
		@include spacer(3);
	}
	#intake-files-results{
		.table-striped > tbody{
			tr{
				&:hover,&:focus,&:nth-of-type(odd){
					background: $siteBackground;
				}
			}
		}
	}
}//end view intake files

/* self help library views
--------------------------- */

/* MAIN TOPICS GRIDS */
.view-id-self_help_library_topics.view-display-id-home_self_help_grid {
	max-width: 980px;
	position: relative;
	padding-top: 3em;
	padding-bottom: 3em;
	@include auto;
	&:after{
		@include fullBack($dirtyWhite);
	}
	.view-header{
		text-align: center;
		@include spacer(3);
		h2{
			font-size: 24px;
			padding-bottom: 1px solid $borderRule;
			@include tab{
				font-size: 26px;
			}
		}
		p{
			a{
				border-bottom: 1px solid $borderRule;
				&:hover,&:focus{
					text-decoration: none;
				}
			}
		}
	}//end view header
	.view-content{
		@include safeFlex(row,wrap,center,null);
	}
	.views-row{
		width: 50%;
		max-width: 240px;
		border-bottom: 1px solid $borderRule;
		border-right: 1px solid $borderRule;
		@include deskMenu{
			max-width: 25%;
		}
		.views-field-name{
			position: relative;
			&:after{
				content: '';
				display: block;
				padding-bottom: 100%;
			}
			.field-content a{
				position: absolute;
				display: block;
				width: 100%;
				height: 100%;
				text-align: center;
				font-size: 16px;
				text-transform: capitalize;
				font-weight: $heavy;
				padding: 10px;
				line-height: 1.15em;
				@include safeFlex(column,null,center,null);
				&:hover,&:focus{
					background: $lightGrey;
				}
				body:not(.english) &{
					font-size: 14px;
				}
				@include smallTab{
					font-size: 18px;
					body:not(.english) &{
						font-size: 18px;
					}
					padding: 10px 20px;
				}
				&:before{
					content: '';
					display: block;
					width: 80px;
					height: 80px;
					@include auto;
					margin-bottom: 10px;
				}
				span{
					display: block;
					min-height: 42px;
				}
			}
		}
		//remove border from bottom row depending on breakpoint
		&:nth-last-child(1),&:nth-last-child(2){
			border-bottom: none;
		}
		&:nth-last-child(3){
			@include tab{
				border-bottom: none;
			}
		}
		&:nth-last-child(4){
			@include deskMenu{
				border-bottom: none;
			}
		}
		// remove right border from last in row depending on breakpoint if the language is read left to right
		body:not(.right-left) &{
			&:nth-child(even){
				@include onlySmall{
					border-right: none;
				}
			}
			&:nth-child(3n+3){
				@include onlyTab{
					border-right: none;
				}
			}
			&:nth-child(4n+4){
				@include deskMenu{
					border-right: none;
				}
			}
		}//end left to right grid borders
		.right-left &{
			&:nth-child(odd){
				@include onlySmall{
					border-right: none;
				}
			}
			&:nth-child(3n+1){
				@include onlyTab{
					border-right: none;
				}
			}
			&:nth-child(4n+1){
				@include deskMenu{
					border-right: none;
				}
			}
		}//end right to left grid borders

		//icon classes
		&.family{
			.views-field-name .field-content a:before{
				@include svgBack(/legal-topics/family,80px,80px);
			}
		}
		&.housing{
			.views-field-name .field-content a:before{
				@include svgBack(/legal-topics/housing,80px,80px);
			}
		}
		&.car{
			.views-field-name .field-content a:before{
				@include svgBack(/legal-topics/car,80px,80px);
			}
		}
		&.money{
			.views-field-name .field-content a:before{
				@include svgBack(/legal-topics/money,80px,80px);
			}
		}
		&.work{
			.views-field-name .field-content a:before{
				@include svgBack(/legal-topics/work,80px,80px);
			}
		}
		&.seniors{
			.views-field-name .field-content a:before{
				@include svgBack(/legal-topics/seniors,80px,80px);
			}
		}
		&.immigration{
			.views-field-name .field-content a:before{
				@include svgBack(/legal-topics/immigration,80px,80px);
			}
		}
		&.government{
			.views-field-name .field-content a:before{
				@include svgBack(/legal-topics/government,80px,80px);
			}
		}
		&.abuse{
			.views-field-name .field-content a:before{
				@include svgBack(/legal-topics/abuse,70px,70px);
			}
		}
		&.disability{
			.views-field-name .field-content a:before{
				@include svgBack(/legal-topics/disability,80px,80px);
			}
		}
		&.youth{
			.views-field-name .field-content a:before{
				@include svgBack(/legal-topics/youth,80px,80px);
			}
		}
		&.planning{
			.views-field-name .field-content a:before{
				@include svgBack(/legal-topics/covid,70px,70px);
			}
		}
		&.criminal{
			.views-field-name .field-content a:before{
				@include svgBack(/legal-topics/criminal,80px,80px);
			}
		}
	}//end views row
}
.view-id-self_help_library_topics.view-display-id-self_help_library_grid{
	@extend .view-id-self_help_library_topics.view-display-id-home_self_help_grid;
}//end main topics grid

/* SELF HELP LIBRARY FILTER BLOCK */
.block-views-exposed-filter-blockself-help-library-search-self-help-search{
	//keywords
	.keyword-wrapper{
		position: relative;
		@include bleed(40px);
		margin-top: 2em;
		.path-node &{
			margin-top: 0;
			@include spacer(-2);
		}
		&:after{
			@include fullBack($darkBlue);
		}
		#edit-actions.form-group{
			margin-bottom: 0;
			.form-submit.button{
				@include onlySmall{
					top: 38px;
				}
				position: absolute;
				top: 42px;
				right: 24px;
				display: block;
				text-indent: 300vw;
				height: 26px;
				width: 26px;
				padding: 0;
				background-image: url(../images/svg/search-toggle-black.svg) !important;
				background-repeat: no-repeat !important;
				background-position: center;
				background-size: 26px 26px !important;
				@include buttonWipe;
				.right-left &{
					right: auto;
					left: 30px;
				}
				@include tab{
					right: 205px;
					.right-left &{
						right: auto;
						left: 205px;
					}
				}
				@include midWidth{
					@include calc(right, "50% - 295px");
					.right-left &{
						right: auto;
						@include calc(left, "50% - 295px");
					}
				}
				&:hover,&:focus{
					@include opacity(.6);
				}
			}
		}
		.form-item-search-api-fulltext{
			padding: 20px;
			max-width: $midWidth;
			@include auto;
			width: 100%;
			@include auto;
			display: block;
			margin-bottom: 0px;
			@include tab{
				padding: 25px 195px 25px;
			}
			label{
				@include visuallyHidden;
				clip: auto;
			}
			input{
				width: 100%;
				font-size: 21px;
				font-style: italic;
				padding: 15px 60px 15px 15px;
				font-family: $sans-serif;
				border: none;
				height: auto;
				background: $siteBackground;
				body:not(.english) &{
					font-size: 16px;
					@include smallTab{
						font-size: 21px;
					}
				}
				.right-left &{
					padding: 15px 15px 15px 60px;
				}
				//prevent yellow chrome autofill inset
				&:-webkit-autofill{
					-webkit-box-shadow: 0 0 0 1000px $white inset;
				}
			}
		}
	}//end keyword wrapper
}//end self help library search block

/* SELF HELP LIBRARY SEARCH */
.view-self-help-library-search{

	.results-header{
		padding: 1em 0;
		position: relative;
		max-width: $maxWidthText;
		@include auto;
		@include spacer(2);
		&:after{
			@include fullBack($lightGrey);
		}
	}//end results view header

	.view-content{
		max-width: $maxWidthText;
		@include auto;
		@include spacer(3);
	}

	.views-row{
		@extend %viewsResourceRow;
		&:not(:last-child){
			padding-bottom: 1.5em;
			border-bottom: 1px solid $borderRule;
			@include spacer(1.5);
		}
		.views-field{
			@include spacer(.5);
			span.views-label{
				font-weight: $heavy;
				margin-right: .5em;
			}
			&.views-field-title{
				font-size: 22px;
			}
		}
	}//end views row

	//results pager
	.pager-wrapper{
		@include resultsPager;
		max-width: $maxWidthText + 40px;
		@include auto;
	}//end pager wrapper

	.view-empty{
		padding-top: 2em;
		max-width: $maxWidthText;
		@include auto;
		@include spacer(3);
		div{
			font-weight: $heavy;
			font-size: 22px;
			@include spacer(.5);
		}
	}//end views empty



}//end self help library



/* SUB TOPICS VIEWS */
.view-id-self_help_library_topics.view-display-id-self_help_subtopics{
	max-width: 980px;
	@include auto;
	.view-content{
		@include safeFlex(row,wrap,center,null);
		@include bleed(20px);
		.views-row{
			width: 100%;
			padding-left: 10px;
			padding-right: 10px;
			margin-bottom: 20px;
			@include smallTab{
				max-width: 260px;
		   }
			@include deskMenu{
				max-width: 25%;
			}
			.views-field-name{
				background: $dirtyWhite;
				border: 1px solid #0A7694;
				position: relative;
				&:after{
					content: '';
					display: block;
					padding: 60px 10px;
					@include smallTab{
						padding: 0 0 100%;
					}
				}
				.field-content a{
					position: absolute;
					width: 100%;
					height: 100%;
					text-align: center;
					font-size: 16px;
					text-transform: capitalize;
					padding: 1.5em 15px;
					font-weight: $heavy;
					color: #0A7694;
					@include safeFlex(column,null,center,null);
					body:not(.english) &{
						font-size: 14px;
					}
					&:hover,&:focus{
						background: $lightGrey;
					}
					@include smallTab{
						font-size: 18px;
						body:not(.english) &{
							font-size: 18px;
						}
					}
				}
			}
		}//end views row
	}//end view content
}//end sub topics view


/* FACT SHEETS */
.view-display-id-self_help_fact_sheets{
	position: relative;
	padding-top: 2em;
	padding-bottom: 2em;
	@include auto;
	@include spacer(4);
	&:after{
		@include fullBack($dirtyWhite);
	}
	.view-header{
		@include spacer(2);
		h2.resource-title{
			font-size: 26px;
			color: $darkBlue;
			padding-bottom: .5em;
			border-bottom: 1px solid $borderRule;
			text-align: center;
		}
	}
	.view-content{
		max-width: $superMaxWidth;
		@include auto;
		.view-content-inner{
			@include safeFlex(row,wrap,null,null);
			@include bleed(30px);
			@include superMaxWidth{
				@include bleed(80px);
			}
		}
	}
	.views-row{
		@extend %viewsResourceRow;
		@include calc(width, "100% - 30px");
		margin-left: 15px;
		margin-right: 15px;
		@include tab{
			@include calc(width, "50% - 30px");
		}
		@include superMaxWidth{
			@include calc(width, "50% - 80px");
			margin-left: 40px;
			margin-right: 40px;
		}
	}
	.views-more-wrapper{
		@extend %viewsMoreWrapper;
		.views-more-content-inner{
			@include safeFlex(row,wrap,null,null);
			@include bleed(30px);
			@include superMaxWidth{
				@include bleed(80px);
			}
		}
		.views-more-button.button-outline{
			position: absolute;
			bottom: -28px;
			left: 50%;
			@include translateX(-50%);
			background: $white;
			&:hover,&:focus{
				background: $green;
			}
		}
	}
}//end self help fact sheets


/* BOOKLETS */
.view-display-id-self_help_booklets{
	@include spacer(2);
	.view-header{
		h3{
			font-size: 24px;
		}
	}

	.view-content-inner{
		@include safeFlex(row,wrap,null,null);
		@include bleed(30px);
		@include superMaxWidth{
			@include bleed(80px);
		}
	}

	.views-row{
		@extend %viewsResourceRow;
		@include calc(width, "100% - 30px");
		margin-left: 15px;
		margin-right: 15px;
		@include tab{
			@include calc(width, "50% - 30px");
		}
		@include superMaxWidth{
			@include calc(width, "50% - 80px");
			margin-left: 40px;
			margin-right: 40px;
		}
	}

	.views-more-wrapper{
		@extend %viewsMoreWrapper;
		.views-more-content-inner{
			@include safeFlex(row,wrap,null,null);
			@include bleed(30px);
			@include superMaxWidth{
				@include bleed(80px);
			}
		}
		.views-more-button.button-outline{
			@include tab{
				@include auto;
			}
		}
	}//end views more wrapper
}//end self help booklets


/* FORMS */
.view-display-id-self_help_forms{
	@include spacer(2);
	.view-header{
		h3{
			font-size: 24px;
		}
	}
	.views-row{
		@extend %viewsResourceRow;
	}
	.views-more-wrapper{
		@extend %viewsMoreWrapper;
	}//end views more wrapper
}//end self help forms


/* WEBSITES */
.view-display-id-self_help_websites{
	@include spacer(2);
	.view-header{
		h3{
			font-size: 24px;
		}
	}
	.views-row{
		@extend %viewsResourceRow;
	}
	.views-more-wrapper{
		@extend %viewsMoreWrapper;
	}//end views more wrapper
}//end self help websites


/* Q & A */
.view-display-id-self_help_q_a{
	@include spacer(2);
	.view-header{
		h3{
			font-size: 24px;
		}
	}
	.views-row{
		@extend %viewsResourceRow;
	}
	.views-more-wrapper{
		@extend %viewsMoreWrapper;
	}//end views more wrapper
}//end self help Q & A

/* VIDEO */
.view-display-id-self_help_videos{
	@include spacer(2);
	.view-header{
		h3{
			font-size: 24px;
		}
	}
	.views-row{
		@extend %viewsResourceRow;
	}
	.views-more-wrapper{
		@extend %viewsMoreWrapper;
	}//end views more wrapper
}//end self help videos


%staffViewTitle{
	position: relative;
	padding: 2em 0;
	color: $white;
	background-color: $darkBlue;
	&:after{
		@include fullBack($darkBlue);
	}
	h1.page-title{
		color: $white;
		margin-bottom: .25em;
		background-color: $darkBlue;
	}
}

%staffTabs{
	padding: 3em 0 12px;
	@include safeFlex(row,wrap,null,null);
	@include deskMenu{
		@include bleed(6px);
	}
	a{
		font-size: 16px;
		padding: .75em .5em;
		min-width: 210px;
		margin-bottom: -2px;
		display: block;
		width: 100%;
		border-color: $borderRule;
		&:hover,&:focus{
			border-color: $green;
			text-decoration: underline;
		}
		.hmong &,.somali &{
			font-size: 15px;
		}
		@include deskMenu{
			@include calc(width, "33.33% - 6px");
			margin-left: 3px;
			margin-right: 3px;
			max-width: 225px;
			font-size: 15px;
			white-space: normal;
			.somali &,.hmong &{
				max-width: none;
			}
		}

		@include maxWidth{
			font-size: 16px;
		}
		&.active-tab{
			background: $darkBlue;
			color: $white;
			@include onlySmall{
				@include safeFlexOrder(3);
			}
			&:hover,&:focus{
				background: $green;
			}
		}
	}//end li a
}//end staffTabs

%staffViewsExposed{
	position: relative;
	padding: 2.5em 0;
	&:after{
		@include fullBack($greenWhite);
	}
	.filter-title{
		font-size: 28px;
		font-weight: $regular;
		padding-bottom: .5em;
		color: $midnight;
		border-bottom: 1px solid $borderRule;
		@include spacer(1);
		.tip-info{
			position: relative;
			top: -10px;
			body:not(.english) &{
				display: none;
			}
		}
	}
	.filters-description{
		@include spacer(1);
		.translation-help{
			font-weight: $heavy;
			body.english &{
				display: none;
			}
		}
	}
	.form-inline{
		.form-item{
			display: block;
			@include spacer(0);
			label{
				display: block;
				@include spacer(.5);
			}
			input{
				width: 100%;
				font-size: 20px;
				@include spacer(.25);
				&.form-text{
					border: 1px solid $borderRule;
					border-radius: 0;
					min-height: 48px;
					body:not(.english) &{
						@include tab{
							font-size: 18px;
						}
						@include deskMenu{
							font-size: 20px;
						}
					}
				}
			}
			.select2-container{
				max-width: none;
				@include spacer(.25);
				.select2-selection__rendered{
					font-size: 20px;
					body:not(.english) &{
						@include tab{
							font-size: 18px;
						}
						@include deskMenu{
							font-size: 20px;
						}
					}
				}
				.select2-selection--multiple{
					padding: 3px 0 0;
					min-height: 48px;
					input{
						@include spacer(0);
						body:not(.english) &{
							@include tab{
								font-size: 18px;
							}
							@include deskMenu{
								font-size: 20px;
							}
						}
					}
				}
				.select2-selection--single{
					padding: 5px 0 0;
					min-height: 48px;
					.select2-selection__placeholder{
						color: $bodyFont;
						@include opacity(.4);
						&:focus{
							@include opacity(.2);
						}
					}
					&[aria-expanded="true"]{
						.select2-selection__placeholder{
							@include opacity(.2);
						}
					}
				}
			}//end select2 container
		}//end generic form items

		.main-filter-wrapper{
			@include clearFix;
			@include bleed(20px);
			@include safeFlex(row,wrap,null,null);
			.main-filter{
				width: 100%;
				padding-left: 10px;
				padding-right: 10px;
				@include spacer(2);
				@include tab{
					width: 33.333%;
				}
			}
			.actions{
				padding-left: 10px;
				padding-right: 10px;
				width: 100%;
				text-align: right;
			}
		}//end main filer wrapper
		//buttons
		.actions button{
			font-weight: $regular;
			width: 160px;
			padding: .75em 1em;
			body:not(.english) &{
				width: 200px;
			}
			@include tab{
				width: 210px;
				body:not(.english) &{
					width: 230px;
				}
			}
			&:first-child{
				margin-right: 16px;
				.right-left &{
					margin-right: 0;
					margin-left: 16px;
				}
			}
		}
	}//end form inline
}//end staffV iewsExposed

%staffResultsHeader{
	.results-header{
		padding: 1em 0;
		position: relative;
		margin-top: -1em;
		@include spacer(2);
		&:after{
			@include fullBack($lightGrey);
		}
	}//end results view header
	.print-results{
		display: none;
	}
}

%ajaxLoader{
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(255,255,255,0.8);
	img{
		display: block;
		width: 64px;
		position: absolute;
		top: 50%;
		left: 50%;
		@include translate(-50%,-50%);
	}
}

/* STAFF VIEWS
------------------ */

/* STAFF BY LOCATION */
.view-location-staff.view-display-id-staff_by_location{
	max-width: 1000px;
	@include auto;
	@include maxWidth{
		max-width: $maxWidth;
	}
	.staff-views-title{
		@extend %staffViewTitle;
	}//end staff views title
	//page tabs
	.staff-view-page-controls{
		@extend %staffTabs;
	}
	//ajax loader
	#ajax-loader{
		@extend %ajaxLoader;
	}
	//exposed forms
	.views-exposed-form{
		@extend %staffViewsExposed;
	}//end views exposed form
	//results header
	.view-header{
		@extend %staffResultsHeader;
	}
	.staff-view-content{
		padding-top: 2em;
		.views-row{
			@include edgeToEdge;
			padding: 2em 20px;
			&:nth-child(even){
				background: $dirtyWhite;
				a:not(.button){
					color: darken($link, 2.5%);
					&:hover,&:focus{
						color: darken($hoverLink, 2.5%);
					}
				}
			}
		}//end views row
		.location-wrapper{
			@include safeFlex(row,wrap,start,start);
			margin-bottom: 1em;
			.location-left{
				width: 100%;
				@include tab{
					&:not(:only-child){
						width: 50%;
						padding-right: 20px;
					}
				}
			}//end location left
			.location-right{
				width: 100%;
				@include tab{
					width: 50%;
					padding-left: 20px;
				}
			}//end location right
		}//end location wrapper
		.location-title{
			h3{
				font-size: 20px;
				margin-bottom: .5em;
			}
		}
		.location-address{
			margin-bottom: 1em;
			.country{
				display: none;
			}
		}
		.location-phone{
			ul{
				@include listReset;
			}
			.field--name-department{
				font-weight: $heavy;
				display: inline-block;
				margin-bottom: 0;
				&:after{
					content: ":";
					margin-right: .5em;
				}
			}
			a{
				display: inline-block;
				margin-right: .5em;
			}
			.field--name-extension{
				display: inline;
				.field--label{
					font-weight: 400;
					padding-right: 0;
				}
			}
		}//ends location phone
		.location-departments{
			ul{
				li{
					@include spacer(2);
				}
				@include listReset;
				.paragraph--type--department{
					h2.widget-title{
						margin-bottom: .5em;
						display: block;
						font-size: 24px;
					}
				}
				table{
					width: 100%;
					td,th{
						padding: .25em .5em;
					}
					thead th{
						background: $black;
						color: $white;
					}
					tbody tr{
						//set table row colors
						&:nth-of-type(odd){
							background: $dirtyWhite;
						}
						&:nth-of-type(even){
							background: $white;
						}
						th{
							background: $black;
							color: $white;
						}
						//give a bit extra room in the row on mobile
						@include onlySmall{
							td{
								padding: .5em 20px;
							}
							//style headers
							& > td.st-key{
								text-transform: uppercase;
								font-weight: $heavy;
							}
						}
						.staff-name,
						.staff-email{
							a{
								color: $teal2;
								&:hover,&:focus{
									color: $orange;
								}
							}
						}
					}//end tr
				}//end table
			}//end ul
		}//end location departments
	}//end staff view content
	//results pager
	.pager-wrapper{
		margin-top: 4em;
		@include edgeToEdge;
		@include resultsPager;
	}
	//no results
	.staff-view-empty{
		padding-top: 2em;
		@include spacer(3);
		max-width: $maxWidthText;
		@include auto;
		font-size: 21px;
	}
}//end staff by location

/* STAFF BY PRACTICE */
.view-location-staff.view-display-id-staff_by_practice{
	max-width: 1000px;
	@include auto;
	@include maxWidth{
		max-width: $maxWidth;
	}
	//ajax loader
	#ajax-loader{
		@extend %ajaxLoader;
	}
	.staff-views-title{
		@extend %staffViewTitle;
	}//end staff views title
	//page tabs
	.staff-view-page-controls{
		@extend %staffTabs;
	}
	//exposed forms
	.views-exposed-form{
		@extend %staffViewsExposed;
	}//end views exposed form
	//results header
	.view-header{
		@extend %staffResultsHeader;
	}
	.staff-view-content{
		padding-top: 2em;
		table{
			width: 100%;
			caption{
				font-weight: $heavy;
				color: $bodyFont;
				font-feature-settings: "liga", "dlig";
				line-height: 1.15em;
				margin-bottom: .5em;
				font-size: 24px;
				text-align: center;
			}
			td,th{
				padding: .25em .5em;
			}
			thead th{
				background: $black;
				color: $white;
			}
			tbody tr{
				//set table row colors
				&:nth-of-type(odd){
					background: $dirtyWhite;
				}
				&:nth-of-type(even){
					background: $white;
				}
				th{
					background: $black;
					color: $white;
				}
				//give a bit extra room in the row on mobile
				@include onlySmall{
					td{
						padding: .5em 20px;
					}
					//style headers
					& > td.st-key{
						text-transform: uppercase;
						font-weight: $heavy;
					}
				}
				.staff-name,
				.staff-email{
					a{
						color: $teal2;
						&:hover,&:focus{
							color: $orange;
						}
					}
				}
			}//end tr
		}//end table
	}//end staff view content
	//results pager
	.pager-wrapper{
		margin-top: 4em;
		@include edgeToEdge;
		@include resultsPager;
	}
	//no results
	.staff-view-empty{
		padding-top: 2em;
		@include spacer(3);
		max-width: $maxWidthText;
		@include auto;
		font-size: 21px;
	}
}//end staff by practice
