/* =================
  NODES
==================== */

//variables
$sideBarWidth: 360px;

//add some spacing between title and node content
h1 + .node{
	padding-top: 1em;
}

.region-content > .node{
	padding-bottom: 3em;
}

/* GLOBAL
------------- */
.path-node{
	&:not(.page-node-type-services){
		.field--name-body{
			max-width: $maxWidthText;
			@include auto;
			@include spacer(2);
		}
	}
	.row.node{
		@include edgeReset;
		& > {
			.col-sm-12{
				padding-left: 0;
				padding-right: 0;
			}
		}
	}
	&.content-one-col{
		.breadcrumb,.page-header{
			max-width: $maxWidthText;
			@include auto;
		}
		.row.node{
			max-width: $maxWidthText;
			@include auto;
		}
	}
	&.content-two-col{
		.breadcrumb,.page-header{
			max-width: $maxWidth;
			@include auto;
			span{
				max-width: $maxWidthText;
				display: block;
			}
		}
		.row.node{
			max-width: $maxWidth;
			@include auto;
		}
	}
}//end path-node



/* SERVICES
---------------- */
.page-node-type-services{
	.breadcrumb{
		max-width: $maxWidth;
		@include auto;
	}
	//main node content
	article.services{
		max-width: $maxWidth;
		@include auto;
		@include spacer(4);
		@include deskMenu{
			@include clearFix;
		}
	}

	.service-info{
		@include spacer(1.5);
		@include mobileMenu{
			width: 100%;
			border-top: 1px solid $borderRule;
			border-bottom: 1px solid $borderRule;
			@include safeFlexOrder(2);
			@include spacer(1.5);
		}
		@include deskMenu{
			float: right;
			width: 260px;
		}
		.service-info-inner{
			padding: 1.5em 0;
			@include mobileMenu{
				padding-left: 19px;
			}
			@include deskMenu{
				padding: 1.5em 0 1.5em 40px;
				.right-left &{
					padding: 1.5em 40px 1.5em 0;
				}
			}
			&.is-clinic{
				padding-bottom: 80px;
			}
			& > div{
				@include spacer(.25);
				&.field--label-inline{
					.field--label{
						padding-right: 0;
					}
				}
			}
			.tip-info{
				top: -4px;
				margin-left: -19px;
			}
			.field--name-special-requirements{
				span{
					display: block;
				}
			}
		}//end service info inner
		.clinic-badge{
			background: $green;
			width: 100px;
			height: 100px;
			border-radius: 50%;
			margin-left: 40px;
			position: relative;
			top: -50px;
			@include spacer(-1.5);
			@include safeFlex(column,null,center,center);
			.right-left &{
				margin-left: 0;
				margin-right: 40px;
			}
			body:not(.english) &{
				width: 125px;
				height: 125px;
				font-size: 14px;
			}
			span{
				color: $white;
				text-transform: uppercase;
				font-weight: $heavy;
				text-align: center;
			}
		}
	}//end service-info

	.service-text{
		a{
			word-break: break-word;
		}
		@include mobileMenu{
			width: 100%;
			@include safeFlexOrder(1);
		}
		@include deskMenu{
			padding-right: 40px;
			@include calc(width, "100% - 260px");
			border-right: 1px solid $borderRule;
		}
		h1{
			.new-title{
				max-width: calc(100% - 60px);
			}
		}
		.field.new{
			float: right;
			.new-listing{
		    background: $darkBlue;
		    font-weight: $heavy;
		    text-transform: uppercase;
		    color: $white;
		    border-radius: 50%;
		    width: 60px;
		    height: 60px;
		    line-height: 60px;
		    text-align: center;
				font-size: 14px;
			}
		}
		.field--name-application,.field--name-body{
			@include spacer(1.5);
			&:after{
				content: none;
			}
		}
		.field--name-service-areas{
			@include spacer(1.5);
			.field--item{
				display: inline;
				&:not(:last-child){
					&:after{
						content: ',';
					}
				}
			}
		}//end service areas
	}//end service text

	.service-card-wrapper{
		@include mobileMenu{
			width: 100%;
			@include safeFlexOrder(3);
		}
		@include deskMenu{
			padding-right: 40px;
			@include calc(width, "100% - 260px");
		}
		.service-toggle-wrapper{
			@include safeFlex(row,wrap,null,null);
			@include deskMenu{
				@include bleed(6px);
			}
			.service-toggle{
				font-size: 16px;
				padding: .75em .5em;
				min-width: 210px;
				margin-bottom: -2px;
				display: block;
				width: 100%;
				border-color: $borderRule;
				&:hover,&:focus{
					border-color: $green;
					text-decoration: underline;
				}
				.hmong &,.somali &{
					font-size: 15px;
				}
				@include deskMenu{
					@include calc(width, "33.33% - 6px");
					margin-left: 3px;
					margin-right: 3px;
					max-width: 225px;
					font-size: 15px;
					white-space: normal;
					.somali &,.hmong &{
						max-width: none;
					}
				}

				@include maxWidth{
					font-size: 16px;
				}
				&.active{
					background: $darkBlue;
					color: $white;
					@include onlySmall{
						@include safeFlexOrder(3);
					}
					&:hover,&:focus{
						background: $green;
					}
				}
				&.loon {
					min-width: 205px;
					@include deskMenu{
						max-width: none;
						width: 100%;
					}
					@include maxWidth{
						@include calc(width, "25% - 6px");
					}
				}
			}
		}//end service toggle wrapper
		.service-card{
			display: none;
			padding: 2em;
			position: relative;
			border: 2px solid $borderRule;
			@include clearFix;
		}
		// service and staff notes card
		.field--name-staff-notes{
			@include spacer(1.5);
		}

		//legal topics card
		.field--name-legal-resources-topics{
			.field--item{
				// position: relative;
				// padding: 3px 0 3px 20px;
				// @include spacer(1.5);
				// &:before{
				// 	content: '';
				// 	display: inline-block;
				// 	width: 10px;
				// 	height: 10px;
				// 	background: $lightBlue;
				// 	position: absolute;
				// 	top: 10px;
				// 	left: 0;
				// }
			}
			.term-tree-list{
				@include topicsTree;
			}//end term tree list
		}//end views field resources legal topics

		//contact info
		.contact-hours{
			@include smallTab{
				&.clinic > .locations{
					&:not(:only-child){
						width: 50%;
						padding-right: 20px;
						float: left;
					}
					& > .location-wrapper{
						margin-bottom: 40px;
						&:only-child{
							.location-name{
								display: none;
							}
						}
					}
					.schedule-info{
						width: 50%;
						padding-left: 20px;
						float: left;
					}
				}
				&:not(.clinic){
					& > .locations > .location-item{
						@include clearFix;
						margin-bottom: 40px;
						&:only-child{
							.location-name{
								display: none;
							}
						}
						&:not(:only-child){
							.location-schedule{
								margin-top: 5px;
							}
						}
					}
					.location-wrapper:not(:only-child){
						width: 50%;
						padding-right: 20px;
						float: left;
					}
					.location-schedule{
						width: 50%;
						padding-left: 20px;
						float: left;
					}
				}
			}//end smalltab

			.location-name{
				font-size: 22px;
				font-weight: $heavy;
			}
			.field{
				@include spacer(0);
			}
			.location-wrapper > .field{
				@include spacer(1.5);
			}

			.address{
				.country{
					display: none;
				}
			}
			.field--name-phone{
				.field--item{
					.field--name-department{
						font-weight: $heavy;
						display: inline-block;
						&:after{
							content: ":";
							margin-right: .5em;
						}
					}
					a{
						display: inline-block;
						margin-right: .5em;
					}
					.field--name-extension{
						display: inline;
						.field--label{
							font-weight: 400;
							padding-right: 0;
						}
					}
				}
			}//end phone
			.field--name-contacts{
				.field--item{
					& > li:not(:last-child){
						@include spacer(.5);
					}
				}
			}//end location contact
		}//end contact info

		//location schedule
		.location-schedule{
			.field{
				@include spacer(0);
			}
			@include smallTab{
				&:not(:only-child){
					width: 50%;
					padding-left: 20px;
					float: left;
				}
			}
			& > .field{
				@include spacer(1.5);
				& > .field--item{
					@include spacer(.5);
				}
			}
		}//end location-schedule

		//clinic schedule
		.schedule-info{
			.field{
				@include spacer(0);
				& > .field--item{
					@include spacer(.5);
				}
			}
			@include smallTab{
				&:not(:only-child){
					width: 50%;
					padding-left: 20px;
					float: left;
				}
			}
		}//end schedule info

		.field--name-email{
			clear: both;
		}
	}//end service card wrapper

	//link to main loon page
	.field--name-loon-link{
		padding-top: 2.5em;
		a{
			font-size: 16px;
			padding: .75em 2em;
			width: 210px;
		}
	}//end link to main loon page

	//not logged in
	&:not(.user-logged-in){
		.service-card-wrapper{
			.contact-hours{
				.field--name-contacts{
					display: none;
				}
			}
		}
	}//end not logged in
}//end services


/* BOOKLETS
---------------- */
.page-node-type-booklet{
	h1.page-header{
		max-width: $maxWidthText;
	}
}
.node--type-booklet{
	padding-bottom: 1em !important;
	.bs-region--top{
		.field--name-read-this-in{
			@include spacer(1.5);
			.field--item{
				display: inline-block;
				&:not(:last-child){
					&:after{
						content: ",";
					}
				}
			}
		}
	}
	.bs-region--main{
		//re-order fields on mobile
		@include mobileMenu{
			@include safeFlex(column,null,null,null);
			.chapter-navigation{
				@include safeFlexOrder(1);
			}
			.field--name-chapters{
				@include safeFlexOrder(2)
			}
			.field--name-order-booklet{
				@include safeFlexOrder(3);
			}
			.field--name-files{
				@include safeFlexOrder(4);
			}
			.field--name-jump-to-eligibility {
				@include safeFlexOrder(5);
			}
			.field--name-related-content {
				@include safeFlexOrder(6);
			}
		}
		@include deskMenu{
			@include clearFix;
		}
	}//end main region
	.field--name-authored-by{
		@include spacer(1.5);
	}
	.chapter-navigation{
		@include deskMenu{
			float: left;
			width: $sideBarWidth;
		}
	}
	.field--name-body{
		margin-left: 0 !important;
		margin-right: 0 !important;
	}
	.field--name-chapters{
		max-width: 1020px;
		@include auto;
		@include spacer(3);
		& > .field--item{
			display: none;
		}
		@include deskMenu{
			margin-left: $sideBarWidth;
			padding-left: 50px;
			.field--name-ec-long-text{
				&:after{
					clear: none;
				}
			}
		}
		.content-three-col &{
			@include superMaxWidth{
				margin-right: $sideBarWidth;
				padding-right: 50px;
			}
		}
		.paragraph--type--chapter{
			.widget-title{
				@include visuallyHidden;
			}
			.field--name-chapter-sections{
				& > .field--item{
					@include spacer(1.5);
				}
				& > .field--item:last-child{
					@include spacer(3);
				}
				.chapter-section-title{
					color: $darkBlue;
				}
			}
		}//end chapter

		.chapter-page-nav{
			border-top: 1px solid $borderRule;
			@include clearFix;
			.chapter-pager-item{
				float: left;
				width: 50%;
				padding-top: 1em;
				font-weight: $heavy;
				@include smallTab{
					font-size: 22px;
				}
				&:first-child{
					padding-right: 10px;
					text-align: left;
				}
				&:last-child{
					padding-left: 10px;
					text-align: right;
				}
				a{
					&.disabled{
						display: none;
					}
				}
			}//end chapter-page-item
		}//end chapter-page-nav
	}//end chapters
	.field--name-order-booklet{
		text-align: center;
		@include deskMenu{
			float: left;
			clear: left;
			width: $sideBarWidth;
		}
		@include superMaxWidth{
			float: right;
			clear: none;
		}
		a.button{
			padding-left: 1em;
			padding-right: 1em;
			width: 100%;
			max-width: 360px;
		}
	}//end order a booklet
	.field--name-files,
	.field--name-related-content{
		width: 100%;
		max-width: 360px;
		@include auto;
		@include deskMenu{
			float: left;
			clear: left;
			width: $sideBarWidth;
		}
		@include superMaxWidth{
			float: right;
			clear: right;
		}
		&.field--label-above{
			& > .field--label{
				text-align: center;
				padding-top: 1em;
				padding-left: 1em;
				padding-right: 1em;
				padding-bottom: 1em;
				background: $grey;
				text-align: center;
				border-bottom: 0;
				color: $white;
				width: 100%;
				margin-bottom: 0;
				&:after{
					content: none;
				}
			}//end field label
		}//end field label above
		& > .field--items{
			padding: 1em 30px;
			border-left: 1px solid $grey;
			border-right: 1px solid $grey;
			border-bottom: 1px solid $grey;
		}
	}//end file and related content
	.field--name-related-content .fact-sheet-icon,
	.field--name-related-content .checklist-icon {
		height: 18px;
		width: 18px;
		margin-right: .35em;
	}
	.field--name-jump-to-eligibility {
		width: 100%;
		max-width: 360px;
		@include auto;
		.somali &,.hmong &,.spanish &{
			display: none !important;
		}
		@include deskMenu{
			float: left;
			clear: left;
			width: $sideBarWidth;
		}
		@include superMaxWidth{
			float: right;
			clear: right;
		}
	}
}//end BOOKLETS


/* FACT SHEET
---------------- */
.page-node-type-fact-sheet{
	h1.page-header{
		max-width: $maxWidthText;
	}
	.field-checklist{
		margin-bottom: 1.5em;
		.button {
			width: 100%;
			padding-left: 1.5em;
			padding-right: 1.5em;
			margin-bottom: 1.5rem;
			font-size: $font-size-large;
			background: $green;
			border-color: $green;
			transform: none;
			max-width: 360px;
			margin-bottom: 0;
			@include auto;
			display: block;
			@include midWidth{
				display: inline-block;
			}
		}
	}
}
.node--type-fact-sheet,
.node--type-checklist{
	@include desk{
		padding-bottom: 0 !important;
	}
	.bs-region--main{
		//re-order fields on mobile
		@include mobileMenu{
			@include safeFlex(column,null,null,null);
			.field--name-body{
				@include safeFlexOrder(1);
				width: 100%;
			}
			.field--name-faq{
				@include safeFlexOrder(2);
				width: 100%;
			}
			.factsheet-sidebar,
			.checklist-sidebar{
				@include safeFlexOrder(3);
			}
		}
		@include deskMenu{
			@include clearFix;
		}
	}//end main region
	.field--name-authored-by{
		@include spacer(1.5);
	}
	.field--name-read-this-in{
		@include spacer(1.5);
		.field--item{
			display: inline-block;
			&:not(:last-child){
				&:after{
					content: ",";
				}
			}
		}
	}
	.field--name-body{
		@include spacer(3);
		.content-two-col &{
			@include deskMenu{
				margin-left: $sideBarWidth !important;
				padding-left: 50px;
				&:after{
					clear: none;
				}
			}
		}
	}//end field name body
	.field--name-audio{
		.content-two-col &{
			@include deskMenu{
				margin-left: $sideBarWidth !important;
				padding-left: 50px;
			}
		}
		a.modal-trigger{
			text-decoration: none;
			&:hover,&:focus{
				.audio-icon{
					opacity: .6;
				}
				span{
					text-decoration: underline;
				}
			}
			.audio-icon{
				display: inline-block;
				vertical-align: bottom;
				max-width: 60px;
				padding-right: 10px;
			}
			span{
				font-weight: $heavy;
				font-size: 22px;
			}
		}//end modal trigger
		.field--name-field-media-audio-file{
			text-align: center;
		}
	}//end audio field
	.field--name-faq{
		max-width: 1020px;
		@include auto;
		@include spacer(3);
		.content-two-col &{
			@include deskMenu{
				margin-left: $sideBarWidth;
				padding-left: 50px;
			}
		}
		& > .field--item:not(:last-child){
			@include spacer(1.5);
		}
	}//end faq

	.factsheet-sidebar,
	.checklist-sidebar{
		width: 100%;
		@include auto;
		@include deskMenu{
			float: left;
			width: $sideBarWidth;
		}
	}

	.field--name-files,
	.field--name-related-content,
	.field--name-jump-to-eligibility {
		width: 100%;
		max-width: 360px;
		@include auto;
		@include deskMenu{
			float: left;
			width: $sideBarWidth;
		}
		&.field--label-above{
			& > .field--label{
				text-align: center;
				padding-top: 1em;
				padding-left: 1em;
				padding-right: 1em;
				padding-bottom: 1em;
				background: $grey;
				text-align: center;
				border-bottom: 0;
				color: $white;
				width: 100%;
				margin-bottom: 0;
				&:after{
					content: none;
				}
			}//end field label
		}//end field label above
		& > .field--items{
			padding: 1em 30px;
			border-left: 1px solid $grey;
			border-right: 1px solid $grey;
			border-bottom: 1px solid $grey;
		}
	}//end file and related content
	.field--name-related-content {
		margin-bottom: 1.5em;
		.fact-sheet-icon,
		.checklist-icon {
			height: 18px;
			width: 18px;
			margin-right: .35em;
		}
	}
//end sidebar
	.field.quiz-link-field{
		display: none;
		@include deskMenu{
			display: block;

		}
		.quiz-link-wrapper{
			display: none;
			padding: 20px;
			border: 1px solid $borderRule;
			box-shadow: 4px 6px 6px rgba(35,35,35,0.2);
			width: $sideBarWidth;
			position: fixed;
			top: 200px;
			left: -100vw;
			min-height: 190px;
			background: $white;
			z-index: 99;
			&.small-quiz-link{
				width: 74px;
				left: 0 !important;
				min-height: 0;
				padding: 20px 10px;
				.quiz-link-close{
					display: none;
				}
				h2{
					text-align: center;
					margin-bottom: 0;
					.quiz-link-icon{
						margin-left: -2px;
					}
					.quiz-link-text{
						font-size: 16px;
						display: block;
						text-align: center;
						margin-bottom: 3px;
					}
					i{
						display: block;
					}
				}
				p{
					display: none;
				}
			}
		}
		.quiz-link-close{
			position: absolute;
			top: 0;
			right: 0;
			display: block;
			width: 40px;
			height: 40px;
			background-image: url('../images/svg/quiz-close.svg');
			background-repeat: no-repeat;
			background-size: 25px;
			background-position: center;
			&:hover,&:focus{
				background-image: url('../images/svg/quiz-close-hover.svg');
			}
		}
		h2{
			font-size: 22px;
			.quiz-link-icon{
				display: inline-block;
				vertical-align: bottom;
				height: 60px;
				width: 60px;
				background-image: url('../images/svg/quiz-icon.svg');
				background-repeat: no-repeat;
				background-position: center;
				background-size: cover;
				margin-left: -10px;
			}
			i{
				display: none;
			}
		}
	}//end field quiz link
}//end fact sheets

.node--type-checklist{
	.field--name-body{
		.content-two-col &{
			@include deskMenu{
				margin-left: 0 !important;
				padding-left: 0;
			}
		}
	}//end field name body
	.field--name-faq{
		.count{
			position: absolute;
			color: $white;
			background-color: $darkBlue;
			text-transform: none;
			text-align: left;
			padding: 18px 16px;
			letter-spacing: 1px;
			font-weight: $heavy;
			line-height: 1.15em;
			font-size: 22px;
			@include tab{
				font-size: 28px;
				padding: 20px 20px;
			}
			@include desk{
				padding: 24px 10px 24px 40px;
				font-size: 36px;
			}
			&.complete{
				color: $darkGrey;
				background-color: $greenWhite;
			}
		}
		.checklist-accordion{
			height: 36px;
			width: 36px;
			border-radius: 6px;
			position: absolute;
			background-color: $white;
			margin: 12px 16px 13px 16px;
			right: 0;
			@include safeFlex(row,nowrap,center,center);
			cursor: pointer;
			@include tab{
				margin: 20px 40px 20px 40px;
			}
			@include desk{
				margin: 27px 40px 27px 40px;
			}
			&:hover,&:focus{
				background-color: $dirtyWhite;
			}
			&::before{
				content: url(../images/chevron.svg);
				height: 24px;
				width: 24px;
				@include transAll(.3s);
			}
			&::before{
				transform: rotate(180deg);
			}
			&.active{
				&::before{
					transform: rotate(360deg);
				}
			}
		}
	}
}

.node--type-booklet,
.node--type-fact-sheet,
.node--type-checklist {
	.field--name-jump-to-eligibility {
		.somali &,.hmong &,.spanish &{
			display: none !important;
		}
		.button {
			width: 100%;
			padding-left: 1.5em;
			padding-right: 1.5em;
			font-size: $font-size-large;
			background: $green;
			border-color: $green;
			transform: none;
			margin-bottom: 0;
		}
		&.fixed-to-top {
			@include mobileMenu {
				position: fixed;
				z-index: 100;
				top: 0;
				left: 0;
				width: 100%;
				max-width: 100%;
			}
		}
	}
}
.user-logged-in:not(.role-provider) {
	.field--name-jump-to-eligibility {
		&.fixed-to-top {
			@include mobileMenu {
				top: 38px;
			}
		}
	}
}

/* ADDITIONAL LEGAL RESOURCES
-------------------------------- */
.page-node-type-legal-resources{
	.page-header,.breadcrumb{
		max-width: $maxWidthText;
		@include auto;
	}
	.row.node{
		max-width: $maxWidthText;
		@include auto;
	}
	.bs-region--top{
		.field--name-authored-by{
			@include spacer(1.5);
		}
		.field--name-read-this-in{
			@include spacer(1.5);
			.field--item{
				display: inline-block;
				&:not(:last-child){
					&:after{
						content: ",";
					}
				}
			}
		}
	}
	.legal-resource-content{
		&:first-child{
			margin-top: 2em;
		}
		@include spacer(3);
		& > .field{
			&.field--label-above{
				& > .field--label{
					text-align: center;
					padding-top: 1em;
					padding-left: 1em;
					padding-right: 1em;
					padding-bottom: 1em;
					background: $grey;
					text-align: center;
					border-bottom: 0;
					color: $white;
					width: 100%;
					margin-bottom: 0;
					&:after{
						content: none;
					}
				}//end field label
				&.field--name-q-a{
					& > .field--label{
						@include spacer(1);
					}
				}
				&.field--name-video{
					h2.widget-title{
						padding-top: 1em;
					}
				}
			}//end field label above
			&.field--label-inline{
				& > .field--item{
					display: inline-block;
					vertical-align: middle;
				}
			}
		}//end field
	}//end legal resources
}//end legal resources


/* LANDING PAGE
----------------- */
.page-node-type-landing-page:not(.path-frontpage){
	.region-content{
		max-width: 100% !important;
		@include spacer(2);
	}
	.inner-landing-page-top-container{
		@include ultraMaxWidth{
			@include calc(margin-left, "-50vw + 880px");
			@include calc(margin-right, "-50vw + 880px");
		}
		&.checklists{
			padding: 0 20px;
			@include edgeToEdge;
			@include ultraMaxWidth{
				@include edgeReset;
				@include calc(margin-left, "-50vw + 880px");
				@include calc(margin-right, "-50vw + 880px");
			}
			.inner-landing-page-top{
				width: 100%;
			}
			& + .field--name-oc-widgets{
				@include edgeToEdge;
				@include ultraMaxWidth{
					@include edgeReset;
					@include calc(margin-left, "-50vw + 880px");
					@include calc(margin-right, "-50vw + 880px");
				}
				& > .field--item{
					width: 100%;
					max-width: none;
					margin: 0;
					.view-header{
						background-color: $greenWhite;
						padding: 4.25rem 20px 0 20px;
						& > *{
							max-width: $maxWidth;
							@include auto;
						}
						h2{
							font-weight: $regular;
							border-bottom: 1px solid;
							padding-bottom: 10px;
							font-size: 28px;
							margin-bottom: 3rem;
						}
						p:first-of-type{
							margin-bottom: 3rem;
							font-size: 20px;
						}
						p:last-of-type{
							padding-bottom: 3rem;
							font-weight: $heavy;
							font-size: 20px;
						}
					}
					.view-filters{
						background-color: $greenWhite;
						padding: 0 20px 10px 20px;
						margin-bottom: 0;
						@include tab{
							padding-bottom: 30px;
						}
						& > *{
							max-width: $maxWidth;
							@include auto;
						}
						.form--inline{
							@include safeFlex(column,wrap,start,start);
							.form-item {
								@include safeFlex(column,wrap,start,start);
							}
						}
						label{
							font-size: 20px;
					    font-weight: $regular;
					    margin-bottom: 5px;
						}
						.form-item-legal-resources-topics-target-id{
							margin-bottom: 3rem;
						}
						button{
							font-weight: $regular;
							min-width: 211px;
							&#edit-reset{
								background: $greenWhite;
								color: $teal;
								border: 1px solid $teal;
								&:hover,&:focus{
									background-color: $offWhite;
								}
							}
						}
					}
					.result-count{
						background-color: $dirtyWhite;
						padding: 1.5rem 20px;
						margin-bottom: 4rem;
						@include tab{
							margin-bottom: 7rem;
						}
						& > div{
							max-width: $maxWidth;
							@include auto;
							font-weight: $heavy;
							span{
								font-weight: $regular;
							}
						}
					}
					.view-empty{
						text-align: center;
						padding-top: 10rem;
						padding-bottom: 10rem;
					}
					.view-content{
						padding: 0 20px;
						.views-row{
							margin-bottom: 3rem;
							max-width: $maxWidth;
							@include auto;
							&:not(:last-of-type){
								border-bottom: 1px solid;
							}
						}
						.views-field-title{
							font-weight: $heavy;
							font-size: 32px;
							padding-bottom: 14px;
							position: relative;
							padding-left: 54px;
							@include tab{
								font-size: 36px;
								padding-left: 60px;
							}
							&::before{
								content: url(../images/checkmark.png);
								display: block;
								position: absolute;
								left: 0;
								top: 1px;
								height: 36px;
								width: 36px;
								@include tab{
									top: 4px;
								}
							}
						}
						.views-field-body{
							p{
								padding-bottom: 3.5rem;
								padding-left: 54px;
								@include tab{
									padding-left: 60px;
								}
							}
						}
					}
					.view-footer{
						background-color: $teal;
						padding: 2.25rem 20px 0;
						margin-bottom: 4rem;
						color: $white;
						@include tab{
							margin-bottom: 5rem;
						}
						& > div{
							max-width: $maxWidth;
							font-weight: $heavy;
							@include safeFlex(row,wrap,justify,center);
							@include auto;
							span{
								font-weight: $regular;
								padding-right: 10px;
							}
							& > div{
								padding-bottom: 18px;
								width: 100%;
								padding-right: 0;
								text-align: center;
								@include smallTab{
									padding-right: 4rem;
									width: auto;
									text-align: left;
								}
							}
						}
						.pager-nav{
							padding-bottom: 18px;
							@include auto;
							@include smallTab{
								margin-left: 0;
								margin-right: 0;
							}
							.pagination{
								margin: 0;
								.pager__item--previous,
								.pager__item--first,
								.pager__item--next,
								.pager__item--last{
									display: none;
								}
								.pager__item{
									a{
										cursor: pointer;
										border: none;
										border-top-left-radius: 0;
										border-top-right-radius: 0;
										border-bottom-left-radius: 6px;
										border-bottom-right-radius: 6px;
										margin-left: 0;
								    padding: 0.15rem 0.95rem;
										font-weight: $regular;
										background-color: transparent;
										color: $white;
										&:hover,&:focus{
											opacity: .7;
										}
									}
								}
								.pager__item.is-active.active{
									a{
										color: $teal;
										background-color: $white;
									}
								}
							}
						}
					}
					.view-request{
						margin-bottom: -4rem;
						padding: 0 20px;
						& > div{
							@include safeFlex(column,wrap,center,center);
							@include maxWidth {
								@include safeFlex(row,wrap,center,center);
							}
						}
						span{
							font-size: 40px;
							font-weight: $heavy;
							color: $teal;
							text-align: center;
							padding-bottom: 3rem;
							@include maxWidth {
								padding-bottom: 0;
								padding-right: 4rem;
							}
						}
						a{
							background-color: $teal;
							color: $white;
							padding: 2.25rem;
							font-weight: $heavy;
						}
					}
				}
			}
		}
	}

	.inner-landing-page-top{
	  	position: relative;
	  	@include edgeToEdge;
	  	@include calc(width, "100% + 40px");
	  	@include deskMenu{
	  		@include safeFlex(row,wrap,end,stretch);
	  	}
	  	@include ultraMaxWidth{
	  		@include auto;
	  	}
	  	.intro-image{
	  		width: 100vw;
	  		background-size: cover !important;
	  		@include mobileMenu{
	  			height: 56.25vw;
	  			min-height: 280px;
	  			max-height: 470px;
	  		}
	  		@include deskMenu{
	  			max-width: 50%;
	  			@include safeFlexOrder(2);
	  		}
	  	}//end intro image
	  	.landingintro-inner{
	  		padding: 2em 20px 2.5em;
	  		@include mobileMenu{
	  			max-width: 520px;
	  			@include auto;
	  			h1,h2{
	  				text-align: center;
	  			}
	  		}
	  		@include deskMenu{
	  			width: 50%;
	  			max-width: 880px;
	  			padding: 3em 80px 3.5em;
				@include safeFlexOrder(1);
				@include safeFlex(column,null,center,null);
	  		}
	  		@include maxWidth{
	  			min-height: 470px;
	  			background: $siteBackground;
	  		}
	  		@include superMaxWidth{
	  			min-height: 400px;
	  			padding: 3em 120px 3.5em 145px;
	  		}
	  		h1{
	  			font-size: 40px;
	  			color: $green;
					.checklists &{
						color: $white;
					}
	  			@include superMaxWidth{
	  				font-size: 56px;
	  			}
	  		}
	  		.field--name-subheader{
	  			@include spacer(1.5);
	  			h2{
		  			font-size: 24px;
		  			font-weight: $regular;
						.checklists &{
							color: $white;
						}
		  		}
	  		}
	  		.field--name-body{
	  			@include spacer(1.5);
	  		}
	  	}//end home intro inner
	}//end landing-page-top
	&.no-banner{
		.inner-landing-page-top-container{
			@include ultraMaxWidth{
				background: none;
			}
			&.checklists{
				background-color: $darkBlue;
				.landingintro-inner{
					background-color: $darkBlue;
					padding: 4.25rem 0;
					h1{
						color: $white;
						margin-bottom: 2rem;
		  		}
		  		.field--name-subheader{
						margin-bottom: 0;
		  			h2{
							color: $white;
							margin-bottom: 0;
			  		}
		  		}
				}
			}
		}
		.inner-landing-page-top{
			display: block;
			max-width: $maxWidth;
			margin: 0 auto;
			position: relative;
		}
		.landingintro-inner{
			width: 100%;
			max-width: 100%;
			min-height: auto;
			padding: 2em 0 2.5em;
			background: $siteBackground;
			@include superMaxWidth{
				padding: 3em 40px;
			}
		}
	}//end no-banner
	&.has-banner{
		.landingintro-inner{
			.field--name-body{
				margin-left: 0;
				margin-right: 0;
			}
		}
	}
}//end landing-page (not front)


/* BASIC PAGES
------------------- */
.page-node-type-basic-page{
	&.no-banner{
		.breadcrumb{
			max-width: $maxWidthText;
			@include auto;
		}
	}
	.field--name-banner{
		position: relative;
		@include edgeToEdge;
		@include ultraMaxWidth{
			@include edgeReset;
		}
		&:after{
			@include fullBack($dirtyWhite);
		}
		img{
			width: 100%;
		}
	}//end no-banner
	.field--name-node-title{
		max-width: $maxWidthText;
		@include auto;
	}
}//end basic page


/* SUPPORT BOOK
------------------ */
.page-node-type-support-book{
	padding-bottom: 1em !important;
	.region-header,article.support-book{
		max-width: $maxWidth;
		@include auto;
		@include clearFix;
	}
	.support-navigation{
		@include deskMenu{
			float: left;
			width: $sideBarWidth;
			overflow: hidden;
		}
	}
	.support-book-content{
		.support-title{
			max-width: $maxWidthText;
			@include auto;
			@include deskMenu{
				margin-left: $sideBarWidth !important;
				padding-left: 50px;
			}
		}
		.field--name-body{
			@include spacer(3);
			@include deskMenu{
				margin-left: $sideBarWidth !important;
				padding-left: 50px;
			}
		}
		.field--name-oc-widgets{
			margin-bottom: 0;
			@include auto;
			@include spacer(3);
			@include deskMenu{
				margin-left: $sideBarWidth;
				padding-left: 50px;
			}
		}//end chapters
	}

	//restricted access message
	&:not(.logged-in){
		.main-container{
			position: relative;
			.col-sm-12{
				position: static;
				main{
					min-height: 250px;
				}
			}
		}
		.restricted{
			position: relative;
			min-height: 400px;
			text-align: center;
			.block{
				width: 80%;
				max-width: $maxWidthText + 40px;
				border: 1px solid $borderRule;
				padding: 40px 30px;
				@include auto;
				position: absolute;
				top: 50%;
				left: 50%;
				@include translate(-50%,-50%);
				.field--name-body{
					margin-bottom: 0;
				}
			}
		}
	}//end restricted access
}//end support book

/* STAFF
---------------- */
.page-node-type-staff{
	.breadcrumb,.page-header{
		max-width: $maxWidthText;
		@include auto;
	}
	//main node content
	.node--type-staff{
		max-width: $maxWidthText;
		@include auto;
		@include spacer(4);
		@include deskMenu{
			@include clearFix;
		}
		.field--name-job-title{
			font-weight: $heavy;
			color: $bodyFont;
			font-feature-settings: "liga", "dlig";
			line-height: 1.15em;
			margin-top: -.75em;
			font-size: 24px;
		}//end job title
		.field.field--name-email{
			@include spacer(.5);
		}
		.field.field--name-telephone{
			@include spacer(.5);
		}
	}//end node type staff
	//restricted access message
	&:not(.logged-in){
		.main-container{
			position: relative;
			.col-sm-12{
				position: static;
				main{
					min-height: 250px;
				}
			}
		}
		.restricted{
			position: relative;
			min-height: 300px;
			text-align: center;
			.block{
				width: 80%;
				max-width: $maxWidthText + 40px;
				border: 1px solid $borderRule;
				padding: 40px 30px;
				@include auto;
				position: absolute;
				top: 50%;
				left: 50%;
				@include translate(-50%,-50%);
				.field--name-body{
					margin-bottom: 0;
				}
			}
		}
	}//end restricted access
}//end node type staff

/* LOCATION
---------------- */
.node--type-location{
	.badge{
		background-color: $darkGrey;
	}
}
