/* ============
	BREAKPOINTS & GRID VARIABLES
======================= */

/* BREAKPOINT VARIABLES
------------------------ */
$smallTab: 500px;
$tab: 760px;
$desk: 900px;
$midWidth: 1000px;
$maxWidth: 1200px;
$superMaxWidth: 1500px;
$ultraMaxWidth: 1800px;
$maxWidthText: 900px;
$onlyTab: 760px 979px;
$onlySmall: max-width 759px;
$onlyPhone: max-width 499px; 
$mobileMenu:max-width 979px;
$deskMenu:min-width 980px;



/// mixin - smallTab
@mixin smallTab {
	@include breakpoint($smallTab) {
		@content;
	}
}

/// mixin - tab
@mixin tab {
	@include breakpoint($tab) {
		@content;
	}
}

/// mixin - desk
@mixin desk {
	@include breakpoint($desk) {
		@content;
	}
}

///mixin - maxWidth
@mixin midWidth {
	@include breakpoint($midWidth) {
		@content;
	}
}

///mixin - maxWidth
@mixin maxWidth {
	@include breakpoint($maxWidth) {
		@content;
	}
}

///mixin - superMaxWidth
@mixin superMaxWidth {
	@include breakpoint($superMaxWidth) {
		@content;
	}
}

///mixin - ultraMaxWidth
@mixin ultraMaxWidth {
	@include breakpoint($ultraMaxWidth) {
		@content;
	}
}


///mixin - onlyTab
@mixin onlyTab {
	@include breakpoint($onlyTab) {
		@content;
	}
}


///mixin - onlySmall
@mixin onlySmall {
	@include breakpoint($onlySmall) {
		@content;
	}
}

///mixin - onlyPhone
@mixin onlyPhone {
	@include breakpoint($onlyPhone) {
		@content;
	}
}

///mixin - mobileMenu
@mixin mobileMenu {
	@include breakpoint($mobileMenu) {
		@content;
	}
}

///mixin - deskMenu
@mixin deskMenu {
	@include breakpoint($deskMenu) {
		@content;
	}
}
