/* =================
  OVERRIDES   //  used for direct overrides of bootstrap
==================== */

// jQuery UI style overrides.
@import "../jquery-ui/autocomplete.scss";

/* NAV LINKS */
.nav > li > a:hover,.nav > li > a:focus{
  background: none;
}

/* FORM ELEMENTS */
.form-control{
  color: $bodyFont;
}

/* PAGE TITLES */
.page-header{
  padding-bottom: 0;
  border-bottom: 0;
  margin: 0 0 .5em;
  & + .row.node{
    padding-top: 0;
  }
}

/* FIELD SPACING AND LABELS */
.field{
  &:not(:last-child):not(.field--name-body){
    @include spacer(1.5);
  }
  &.field--label-above{
    & > .field--label{
      display: inline-block;
      padding-bottom: 5px;
      border-bottom: 1px solid $borderRule;
      @include spacer(.5);
      &:after{
        content: ':';
      }
    }
  }
  &.field--label-inline{
    & > *{
      float: none;
    }
    & > .field--label{
      display: inline;
    }
    & > *:not(.field--label){
      display: inline;
      padding-right: 0;
    }
  }
}//end field


/* BOOTSTRAP PANELS */
.form-item.panel.panel-default{
  border: none;
  border-radius: 0;
  box-shadow: none;
  & > .panel-heading{
    background: none;
    border-bottom: 1px solid $borderRule;
    a.panel-title{
      padding: 10px 0;
      position: relative;
      z-index: 2;
      font-size: 18px;
      &:after{
        content: "+";
        display: block;
        position: absolute;
        bottom: -1px;
        right: 0;
        height: 44px;
        width: 44px;
        text-align: center;
        line-height: 44px;
        background: $link;
        color: $white;
        font-size: 22px;
        cursor: pointer;
        z-index: -1;
      }//end after
      &:hover,&:focus{
        &:after{
          background: $green;
        }
      }
      &[aria-expanded="true"]{
        &:after{
          background: $green;
        }
        &:hover,&:focus{
          &:after{
            background: darken($green,5%);
          }
        }
      }
    }//end panel-title
  }//end panel-header
  .panel-body{
    padding: 2em;
  }
}//end default panels


.ui-accordion-header.ui-state-active {
  background: #ccc!important;
  color: #000!important;
  border-color: #ccc;
  margin-bottom: 1px!important;
}

.ui-accordion-header {
  margin-bottom: 1em!important;
  padding: 1em!important;
}

.field-group-accordion-wrapper .field--label {
  color: #777;
}

.ui-accordion-content-active {
  margin-bottom: 1em;
}

.form-item-term-node-tid-depth {
  margin-top: 1em;
}




