/* =================
  PRINT STYLES
==================== */

@media print{
	.navbar-default{
	  display: block;
	  padding-top: 0 !important;
	  border-bottom: 0 !important;
	  margin-top: -25px;
	}

	.navbar-text{
	  font-size: 15px !important;
	}

	#block-languageswitcher,
	#superfish-main{
	  display: none !important;
	}

	.logo img{
	  width: 50% !important;
	}

	a[href]:after {
	  display: none;
	}

	.branding-block{
	  border-bottom: 1px solid black;
	  max-width: 100% !important;
	}

	.branding-block .navbar-text {
	  height: 74px !important;
	  bottom: 3px !important;
	  left: 230px !important;
	}

	.menu--secondary li{
	  display: none;
	}

	.block-alert-banner{
	  display: none;
	}

	.breadcrumb{
	  display: none;
	}

	/* =================
	  SITEWIDE
	==================== */

	.path-site-search .main-container{
	    padding: 20px !important;
	    padding-top: 75px !important;
	}

	.landingintro-inner{
	  min-height: 0 !important;
	  width: 100% !important;
	  max-width: 100% !important;
	}

	.field--name-body{
	  width: 100% !important;
	  max-width: 100% !important;
	}

	.menu--footer{
	  display: none !important;
	}

	#livechat-compact-container{
	  display: none;
	}

	#block-copyright{
	  padding: 0;
	}

	.Prefooter.container{
	  border-top: 1px solid black;
	  border-bottom: 1px solid black;
	  margin-bottom: 20px;
	}

	.footer.container{
	  padding-top: 0;
	}

	.pager-wrapper{
	  display: none !important;
	}

	.block-search-form-block{
	  display: none !important;
	}
	/* =================
	  SELF HELP LIBRARY ICONS
	==================== */

	.family .field-content a:before{
	  background-image: url(../images/svg/legal-topics/family.svg) !important;
	}

	.housing .field-content a:before{
	  background-image: url(../images/svg/legal-topics/housing.svg) !important;
	}

	.money .field-content a:before{
	  background-image: url(../images/svg/legal-topics/money.svg) !important;
	}

	.work .field-content a:before{
	  background-image: url(../images/svg/legal-topics/work.svg) !important;
	}

	.seniors .field-content a:before{
	  background-image: url(../images/svg/legal-topics/seniors.svg) !important;
	}

	.immigration .field-content a:before{
	  background-image: url(../images/svg/legal-topics/immigration.svg) !important;
	}

	.government .field-content a:before{
	  background-image: url(../images/svg/legal-topics/government.svg) !important;
	}

	.abuse .field-content a:before{
	  background-image: url(../images/svg/legal-topics/abuse.svg) !important;
	}

	.disability .field-content a:before{
	  background-image: url(../images/svg/legal-topics/disability.svg) !important;
	}

	.youth .field-content a:before{
	  background-image: url(../images/svg/legal-topics/youth.svg) !important;
	}

	.planning .field-content a:before{
	  background-image: url(../images/svg/legal-topics/covid.svg) !important;
	}

	.criminal .field-content a:before{
	  background-image: url(../images/svg/legal-topics/criminal.svg) !important;
	}

	/* =================
	  HOME PAGE
	==================== */
	.homeintro-inner{
	  width: 100% !important;
	  max-width: 100% !important;
	  min-height: 0 !important;
	}

	.homeintro-inner .intro-button{
	  display: none;
	}

	.homeintro-inner .field--name-post-text{
	  display: none;
	}

	.view-self-help-library-topics .view-header p{
	  display: none;
	}

	.view-id-self_help_library_topics.view-display-id-home_self_help_grid, .view-id-self_help_library_topics.view-display-id-self_help_library_grid{
	  padding-top: 0;
	}

	.block-callout-block img{
	  display: none;
	}


	.lsc-logo{
	  background: url(../images/LSC_Logo_web.png) no-repeat center !important;
	  background-size: contain !important;
	}
	/* =================
	  BASIC PAGES
	==================== */

	.page-node-type-basic-page{
		.region-header{
	  	display: none;
		}
		.field--name-node-title,.region-content .field--name-body{
			max-width: 100%;
		}
	}


	/* ======================
	  CONTENT TYPE - SERVICES
	======================= */

	.page-node-type-services{
	  .region-header{
	  	display: none;
	  }
		.field--name-node-title,.region-content .field--name-body{
			max-width: 100%;
		}
		.collapse{
		  display: block;
		}

		.fade{
		  opacity: 1;
		}
	}

	
	/* =================
	  SELF HELP LIBRARY
	==================== */

	.intro-image{
	  display: none;
	}

	/* Removed search area*/
	.block-views-exposed-filter-blockself-help-library-search-self-help-search{
	  display: none;
	}

	/* Self help library - subtopics */
	.view-display-id-self_help_fact_sheets .views-more-wrapper .views-more-inner, .view-display-id-self_help_booklets .views-more-wrapper .views-more-inner, .view-display-id-self_help_forms .views-more-wrapper .views-more-inner, .view-display-id-self_help_websites .views-more-wrapper .views-more-inner, .view-display-id-self_help_q_a .views-more-wrapper .views-more-inner, .view-display-id-self_help_videos .views-more-wrapper .views-more-inner{
	  display: block !important;
	}
	.legal-topic-content .views-more-button{
	  display: none !important;
	}

	/* Self help library - booklets */
	.page-node-type-booklet{
		.region-header,.chapter-navigation,.field--name-order-booklet,.field--name-files{
			display: none;
		}
		.node--type-booklet .field--name-chapters{
			margin-left: 0;
	  	max-width: 100%;
	  	padding-left: 0;
		}
	}//end booklet

	/* Self help library - fact sheets */
	.page-node-type-fact-sheet{
		.page-header{
			margin-top: 20px;
		}
		.region-header,.field--name-files,.accordion-item .accordion-header:after{
			display: none;
		}
		.node--type-fact-sheet .col-sm-12 .field--name-body,.field--name-faq{
			margin-left: 0 !important;
		  padding-left: 0 !important;
		  width: 100% !important;
		  max-width: 100% !important;
		}
		.paragraph--type--accordion-group .wrapper{
			max-width: 100%;
		}
		.accordion-content{
			display: block !important;
		}
	}//end fact sheet

	/* ====================
	  PROVIDERS AND CLINICS / LOON
	==================== */
	.path-providers-and-clinics,.path-loon{
		.region-header,.pager-wrapper,.view-filters,.results-header,.loon-logo,.account-services{
			display: none;
		}
		.loon-account-block{
			.loon-inner-wrapper{
				.loon-text{
			  	padding: 3em 0 1em 0 !important;
				}
			}
		}
		.view-loon-services{
			.loon-account-block{
			  padding: 0;
			}
			.searching-for{
				display: block !important;
			}
			.print-results{
				display: block;
				padding-bottom: 1em;
				border-bottom: 1px solid $grey;
				span:not(:first-child){
					&:before{
						content:', '
					}
				}
			}
			.loon-view-content {
			  padding-top: 0;
			}
			.views-row.service-row{
				margin-bottom: 0;
			}
			.trigger,.tip-info{
				display: none;
			}
			.service-info{
				border-top: 0;
				border-bottom: 0;
				padding-bottom: 0;
				.clinic-badge{
					background: none;
					border: 1px solid $black;
				}
			}
			.service-card-wrapper{
				.service-toggle-wrapper{
					.service-toggle{
				  	display: none;
					}
				}
				.service-card{
			  	display: block;
			  	&.content-card{
			  		border: 0;
			  		padding: 0 0 2em;
			  	}
			  	&.legal-topics-card{
			  		display: none;
			  	}
			  	&.contacts-card{
			  		border: 0;
			  		padding: 0;
			  	}
				}
			}//end service-card-wrapper
		}//end services views

		

	}//end path providers and clinics

}//end print 

//small screen print
@media print and (max-width:979px){
	.path-providers-and-clinics,.path-loon{
		.view-loon-services{
			.service-info{
				position: relative;
				margin-left: -20px;
				.clinic-badge{
					position: absolute;
					top: 0;
					left: 250px;
				}
			}
		}//en loon services
	}//end path-loon
}//end small screen print
