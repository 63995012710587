/* ========================
   FONTS
========================== */
$fa-font-path: "../webfonts";

/* montserrat-300 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('../fonts/montserrat-v15-latin/montserrat-v15-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/montserrat-v15-latin/montserrat-v15-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/montserrat-v15-latin/montserrat-v15-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/montserrat-v15-latin/montserrat-v15-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/montserrat-v15-latin/montserrat-v15-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/montserrat-v15-latin/montserrat-v15-latin-300.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/montserrat-v15-latin/montserrat-v15-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/montserrat-v15-latin/montserrat-v15-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/montserrat-v15-latin/montserrat-v15-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/montserrat-v15-latin/montserrat-v15-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/montserrat-v15-latin/montserrat-v15-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/montserrat-v15-latin/montserrat-v15-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/montserrat-v15-latin/montserrat-v15-latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/montserrat-v15-latin/montserrat-v15-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/montserrat-v15-latin/montserrat-v15-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/montserrat-v15-latin/montserrat-v15-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/montserrat-v15-latin/montserrat-v15-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/montserrat-v15-latin/montserrat-v15-latin-italic.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-700 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../fonts/montserrat-v15-latin/montserrat-v15-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/montserrat-v15-latin/montserrat-v15-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/montserrat-v15-latin/montserrat-v15-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/montserrat-v15-latin/montserrat-v15-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/montserrat-v15-latin/montserrat-v15-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/montserrat-v15-latin/montserrat-v15-latin-700.svg#Montserrat') format('svg'); /* Legacy iOS */
}

$sans-serif: 'Montserrat', sans-serif;

$light: 300;
$regular: 400;
$heavy: 700;

