/* =================
  TEXT
==================== */

/* HEADERS
------------- */
%headingGlobal{
	font-weight: $heavy;
	color: $bodyFont;
	font-feature-settings: "liga", "dlig";
	line-height: 1.15em;
	margin-top: 0;
	@include spacer(.5);
}

$h1sm: 35px;
$h1md: 40px;

$h2sm: 28px;
$h2md: 30px;
$h2lg: 32px;

$h3sm: 25px;
$h3md: 26px;

$h4sm: 24px;

$h5sm: 23px;

$h6sm: 20px;

$baseFontSize: 18px;

h1{
	@extend %headingGlobal;
	font-size: $h1sm;
	color: $green;
	line-height: 1;
	@include tab{
		font-size: $h1md;
	}
}

h2{
	@extend %headingGlobal;
	font-size: $h2sm;
	@include tab{
		font-size: $h2md;
	}
	@include maxWidth{
		font-size: $h2lg;
	}
}

h3{
	@extend %headingGlobal;
	font-size: $h3sm;
	@include tab{
		font-size: $h3md;
	}
}

h4{
	@extend %headingGlobal;
	font-size: $h4sm;
}

h5{
	@extend %headingGlobal;
	font-size: $h5sm;
}

h6{
	@extend %headingGlobal;
	font-size: $h6sm;
}

h2.block-title{
	font-size: 24px;
}

/* BODY TEXT
---------------- */
html,code{
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-kerning: normal;
	font-synthesis: none; 
}

body {
	color: $bodyFont;
	margin: 0;
	font-family: $sans-serif;
	font-size: $baseFontSize;
	line-height: 1.4;
	font-weight: 400;
}

//clear flaots in common long text fields
.field.body,.field-oc-long-text,.field--type-text-long,.field--type-text-with-summary{
	@include clearFix;
	li{
		margin-bottom: 0;
		ul,ol{
			padding-top: .5em;
			padding-bottom: .5em;
		}
	}
	blockquote{
		margin: 0 0 1.5em 40px;
		border-left: 6px solid $lightBlue;
		font-size: 22px;
		.right-left &{
			margin: 0 40px 1.5em 0;
			border-left: 0;
			border-right: 6px solid $lightBlue;
		}
	}
}//end long text fields

p{
	margin-top: 0;
	&:not(:last-child){
		margin-bottom: 1.5em;
	}
	&:last-child{
		margin-bottom: 0;
	}
}

em{
	font-style: italic;
}
strong{
	font-weight: $heavy;
}

figcaption,.field--name-caption {
	text-align: center;
	color: $bodyFont;
	font-size: 15px;
	font-style: italic;
}

.small,small{
	font-size: 80%;
}

span.highlight{
	background: $highlighter;
}

.warning{
	background: none;
	padding: .5em 1em;
	margin-bottom: 1.5em;
	color: $warning;
	border: 1px solid $warning;
	text-align: center;
}



/* LINKS & BUTTONS
------------- */
a {
	color: $link;
	text-decoration: none;
	overflow-wrap: break-word;
	&:hover,&:focus {
		color: $hoverLink;
		text-decoration: underline;
		.field.body &,.field-oc-long-text &{
			@include transAll(.3s);
		}
	}
}//end a

.main-container {
	h2:not(.accordion-header),h3,h4,h5,h6,p,ul:not(.menu),ol,
	.views-field-website,.warning,.service-card-wrapper .service-card {
		a {
			text-decoration: underline;
		}
	}
}
.main-container {
	nav.breadcrumb ul, nav.tabs ul, ul.pagination .pager__item, 
	nav.chapter-navigation, .quiz-link-wrapper {
		a {
			text-decoration: none !important;
		}
	}
}


//button class
.button,.btn {
	@include button;
}

//outlineButtons
.button-outline{
	@include outlineButton($link);
}

/* LISTS
-------------- */
.field.body,.field-oc-long-text{
	ul,ol {
		//overflow hidden is needed for lists next to floated elements
		overflow: hidden;
		margin: .5em 0 1.5em;
		padding: 0 0 0 30px;
		ul,ol{
			margin: .5em 0 .5em;
			padding: 0 0 0 20px;
		}
	}
}

ul {
	list-style-type: disc;
}

ol {
	list-style-type: decimal;
	&[type="A"]{
		list-style-type: lower-alpha;
	}
	&[type="I"]{
		list-style-type: upper-roman;
	}
}


/* ===============
	 TABLES
============== */

///MIXIN TABLE
/// Default table styling
@mixin table{
	width: 100%;
	caption{
		font-weight: $heavy;
		margin-bottom: .5em;
	}

	td,th{
		padding: .25em .5em;
	}
	thead th{
		background: $black;
		color: $white;
	}

	tbody tr{
		//set table row colors
		&:nth-of-type(odd){
			background: $offWhite;
		}
		&:nth-of-type(even){
			background: $white;
		}
		th{
			background: $black;
			color: $white;
		}
		//give a bit extra room in the row on mobile
		@include onlySmall{
			td{
				padding: .5em 20px;
			}
			//style headers
			& > td.st-key{
				text-transform: uppercase;
				font-weight: $heavy;
			}
		}
	}//end tr
}//end table mixin


/* TABLE STYLING
-------------------- */
.body,.field-oc-long-text,.field--type-text-with-summary{
	table{
		@include table;
		@include spacer(1.5);
	} 
}


/* STACKTABLE
---------------- */
//stack table header on mobile
table{
	.st-key{
		font-size: 16px;
		width: 52%;
		max-width: 200px;
	}
	//stack table td on mobile
	.st-val{
		min-width: 48%;
		font-size: 17px;
		@include breakpoint (400px, true){
			@include calc(max-width, "100% - 200px");
		}
	}
}

.responsive-table{
	& > caption{
		display: none;
	}
}
//hide small stacktable by default
.stacktable{
	&.large-only{
		display: table;
	}
	&.small-only{
		display: none;
	}
}

//hide large stacktable at less than 760
@include onlySmall{
	.stacktable{
		&.large-only{
			display: none;
		}
		&.small-only{
			display: table;
			width: 100%;
			margin-bottom: .5em;
			td{
				display: inline-block;
			}
		}
	}
}
