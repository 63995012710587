/* ========================
   MIXINS
========================== */
///mixin - transAll
@mixin transAll($time){
	transition: all $time ease;
	-moz-transition: all $time ease;
	-webkit-transition: all $time ease;
	-webkit-transform: translateZ(0);
}

///mixin - transAll(.3s)
@mixin transClear{
	transition: none;
	-moz-transition: none;
	-webkit-transition: none;
}

@mixin visuallyHidden{
	position: absolute !important;
	font-size: 1px;
	left: -900vw;
	overflow: hidden;
	height: 1px;
	width: 1px;
	word-wrap: normal;
}


/* HELPER MIXINS (used as a basis for other mixins or for browser prefixing)
----------------- */

///mixin - transform
@mixin transform($transforms) {
	-moz-transform: $transforms;
	-o-transform: $transforms;
	-ms-transform: $transforms;
	-webkit-transform: $transforms;
	transform: $transforms;
}

///mixin - translate
@mixin translate ($x, $y) {
	@include transform(translate($x, $y));
}
@mixin translateX ($x) {
	@include transform(translateX($x));
}
@mixin translateY ($y) {
	@include transform(translateY($y));
}
@mixin translate3d ($x, $y, $z) {
	@include transform(translate3d($x, $y, $z));
}

///mixin - skew
@mixin skew ($x, $y) {
	@include transform(skew(#{$x}deg, #{$y}deg));
}

///mixin - scale
@mixin scale($scalex, $scaley) {
	@include transform(scale($scalex, $scaley));
}
@mixin scale3d($scalex, $scaley, $scalez) {
	@include transform(scale($scalex, $scaley, $scalez));
}

///mixin - rotate
@mixin rotate ($deg) {
	@include transform(rotate(#{$deg}deg));
}
@mixin rotate3d ($rotx, $roty, $rotz, $deg) {
	@include transform(rotate3d($rotx, $roty, $rotz, #{$deg}deg));
}

///mixin - perspective
@mixin perspective ($perspective) {
	@include transform(perspective(#{$perspective}));
}

///mixin - transform-origin
@mixin transformOrigin ($origin) {
	-moz-transform-origin: $origin;
	-o-transform-origin: $origin;
	-ms-transform-origin: $origin;
	-webkit-transform-origin: $origin;
	transform-origin: $origin;
}

///mixin - backface-visibility
@mixin backfaceVisibility($backface){
	-webkit-backface-visiblity: $backface;
	-moz-backface-visiblity: $backface;
	backface-visiblity: $backface;
}

///mixin - backgroundSize
@mixin backgroundSize($back-width, $back-height){
	background-size: #{$back-width} #{$back-height};
}

///mixin - transition
@mixin transition($transition-property, $transition-time, $method) {
	-webkit-transition: $transition-property $transition-time $method;
	-moz-transition: $transition-property $transition-time $method;
	-ms-transition: $transition-property $transition-time $method;
	-o-transition: $transition-property $transition-time $method;
	transition: $transition-property $transition-time $method;
	backface-visibility: hidden;
}

///mixin - opacity
@mixin opacity($opacity){
	opacity: #{$opacity};
	filter: Alpha(opacity=($opacity * 100));
}

///mixin - column-count
@mixin cssColumns($columns, $gap){
	-webkit-column-count: #{$columns};
	-moz-column-count: #{$columns};
	column-count: #{$columns};
	-webkit-column-gap: #{$gap};
	-moz-column-gap: #{$gap};
	column-gap: #{$gap};
}


///mixin - calc
@mixin calc($property, $expression) {
	#{$property}: -webkit-calc(#{$expression});
	#{$property}: -moz-calc(#{$expression});
	#{$property}: calc(#{$expression});
}

///mixin - calcForce
@mixin calcForce($property, $expression) {
	#{$property}: -webkit-calc(#{$expression}) !important;
	#{$property}: -moz-calc(#{$expression}) !important;
	#{$property}: calc(#{$expression}) !important;
}


@mixin svgBack($name, $width, $height){
	background-image: url(../images/svg/#{$name}.svg);
	background-repeat: no-repeat;
	background-position: 0 0;
	background-size: #{$width} #{$height};
}

@mixin resultsPager{
	font-size: 20px;
	position: relative;
	@include safeFlex(row,wrap,null,center);
	&:after{
		@include fullBack($link);
	}
	.page-counter{
		padding: 1.5em 1em;
		color: $white;
		background-color: $teal;
		text-align: center;
		width: 100%;
		@include tab{
			text-align: left;
			width: 50%;
			.right-left &{
				text-align: right;
			}
		}
		& > div{
			background-color: $teal;
			& > span{
				background-color: $teal;
			}
		}
		.result-label{
			text-transform: uppercase;
		}
	}//end page counter
	.results-pager{
		width: 100%;
		@include tab{
			width: 50%;
		}
		.pager-nav{
			@include onlySmall{
				padding-bottom: 1.5em;
			}
			@include tab{
				text-align: right;
				.right-left &{
					text-align: left;
				}
			}
			ul.pagination{
				margin: 0;
				border-radius: 0;
				& > li{
					& > a{
						cursor: pointer;
						border: none;
						border-top-left-radius: 0;
						border-top-right-radius: 0;
						border-bottom-left-radius: 6px;
						border-bottom-right-radius: 6px;
						margin-left: 0;
						color: $link;
						background-color: $white;
						padding: 0 9px;
						&:hover,&:focus{
							opacity: .7;
						}
					}
					&:not(.active){
						& > a{
							background-color: $teal;
							color: $white;
							& > .visually-hidden{
								background-color: $teal;
							}
						}
					}
				}
			}//end ul.pagniation
			li.pager__item--previous,li.pager__item--first,li.pager__item--next,li.pager__item--last{
				display: none;
			}
		}
	}//end pager-nav
}//end results page mixin


/*  STYLE MIXINS
------------------- */

@mixin activeColor{
	color: $activeLink;
	&:hover,&:focus{
		color: $hoverLink;
	}
}

/// mixin - browserWipe
@mixin browserWipe{
	-webkit-appearance: none;
	-moz-appearance: none;
	-ms-appearance: none;
	appearance: none;
}

@mixin buttonWipe{
	@include browserWipe;
	background: none;
	border: none;
	color: inherit;
}


/// mixin - overlay
@mixin overlay{
	&:before{
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		background: $imgOverlay;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
	}
}

///mixin - imageHover
@mixin imageHover{
	border: 1px solid $lightGrey;
	&:hover,&:focus{
		border-color: $darkGrey;
		@include opacity(.7);
		@include transAll(.3s);
	}
}


///mixin - listReset
@mixin listReset {
	list-style: none;
	margin: 0;
	padding: 0;
}



///mixin - fullback
@mixin fullBack($fullback){
	content: '';
	display: block;
	position: absolute;
	top: 0;
	left: -50vw;
	z-index: -1;
	width: 200vw;
	height: 100%;
	background: $fullback;
}

///mixin - fullBack
@mixin halfBack($backColor,$backSide){
	content: '';
	display: block;
	position: absolute;
	top: 0;
	z-index: -1;
	width: 100vw;
	height: 100%;
	background: $backColor;
	@if $backSide == 'right'{
		left: 0;
	}@else{
		right: 0;
	}
}

///mixin - vert rule
@mixin vertRule($rulePosition,$ruleHeight,$ruleColor) {
	content: '';
	display: block;
	position: absolute;
	#{$rulePosition}: 0;
	top: (100% - $ruleHeight) / 2;
	width: 1px;
	height: $ruleHeight;
	background: $ruleColor;
}


@mixin lightBack {
	h2,h3,h4,h5,h6,p,div {
		color: $black;
		&.pane-title{
			border-color: $borderRule;
		}
	}
	:before,:after{
		color: $black;
	}
	a{
		color: $transBlack;
		&:hover,&:focus{
			color: $black;
			@include transAll(.3s);
		}
	}
	.widget-title > a{
		color: $black;
		&:hover,&:focus{
			color: $transBlack;
			@include transAll(.3s);
		}
	}
	figcaption,.field--name-caption {
		color: $black;
	}
}

@mixin darkBack {
	h2,h3,h4,h5,h6,p,div {
		color: $white;
		&.pane-title{
			border-color: $white;
		}
	}
	:before,:after{
		color: $white;
	}
	a{
		color: $transWhite;
		&:hover,&:focus{
			color: $white;
			@include transAll(.3s);
		}
	}
	.widget-title > a{
		color: $white;
		&:hover,&:focus{
			color: $transWhite;
			@include transAll(.3s);
		}
	}
	figcaption,.field--name-caption {
		color: $white;
	}
}

///mixin - button
@mixin button{
	border-radius: 0;
	background-color: $teal;
	border:none;
	color: $white;
	line-height: 1.4;
	padding: .75em 3em;
	display: inline-block;
	text-decoration: none;
	text-align: center;
	font-size: 20px;
	font-weight: $heavy;
	margin-bottom: .5em;
	&:hover,&:focus {
		background-color: $green;
		color: $white;
		@include transAll(.3s);
	}
}

///mixin - outlineButton
@mixin outlineButton($outlineColor){
	@include button;
	background: transparent;
	border: 2px solid $outlineColor;
	color: $outlineColor;
	&:hover,&:focus{
		background: $green;
		color: $white;
		border-color: $green;
		@include transAll(.3s);
	}
}

@mixin triangle($triDirection,$triHeight,$triWidth,$triColor){
	width: 0;
	height: 0;
	-webkit-transform: rotate(0.05deg);
	-moz-transform: scale(1.1);
	transform: rotate(0.05deg);
	$actualWidth: $triWidth / 2;
	$actualHeight: $triHeight / 2;
	@if $triDirection == 'up'{
		border-right: $actualWidth double rgba(0,0,0,0);
		border-left: $actualWidth double rgba(0,0,0,0);
		border-bottom: $triHeight solid $triColor;
	}@else if $triDirection == 'down'{
		border-right: $actualWidth double rgba(0,0,0,0);
		border-left: $actualWidth double rgba(0,0,0,0);
		border-top: $triHeight solid $triColor;
	}@else if $triDirection == 'left'{
		border-top: $actualHeight double rgba(0,0,0,0);
		border-bottom: $actualHeight double rgba(0,0,0,0);
		border-right: $triWidth solid $triColor;
	}@else if $triDirection == 'right'{
		border-top: $actualHeight double rgba(0,0,0,0);
		border-bottom: $actualHeight double rgba(0,0,0,0);
		border-left: $triWidth solid $triColor;
	}
}


/* LAYOUT MIXINS
-------------------- */
$contentW: $maxWidth;
$textW: $maxWidthText;

///mixin - auto
@mixin auto{
	margin-left: auto;
	margin-right: auto;
}

///mixin - edgeToEdge
@mixin edgeToEdge {
	margin-left:-20px;
	margin-right:-20px;
}

///mixin - edgeToEdge
@mixin edgeReset {
	margin-left:0;
	margin-right:0;
}

///mixin - clearfix
@mixin clearFix {
	&::after {
		clear: both;
		content: "";
		display: table;
	}
}

///mixin - paddedContent
@mixin paddedContent{
	@include calc(max-width, "#{$contentW} + 40px");
	padding-left: 20px;
	padding-right: 20px;
}

///mixin - paddedText
@mixin paddedText{
	@include calc(max-width, "#{$textW} + 40px");
	padding-left: 20px;
	padding-right: 20px;
}


///mixin safe-flex
@mixin safeFlex($direction, $wrap, $justify, $align){
	display: flex;
	display: -ms-flexbox;
	flex-direction: $direction;
	-ms-flex-direction: $direction;
	@if $wrap == 'wrap'{
		flex-wrap: wrap;
		-ms-flex-wrap: wrap;
	}@else{
		flex-wrap: nowrap;
		-ms-flex-wrap: none;
	}
	@if $justify == 'start'{
		justify-content: flex-start;
		-ms-flex-pack: start;
	}@else if $justify == 'end'{
		justify-content: flex-end;
		-ms-flex-pack: end;
	}@else if $justify == 'center'{
		justify-content: center;
		-ms-flex-pack: center;
	}@else if  $justify == 'justify'{
		justify-content: space-between;
		-ms-flex-pack: justify;
	}
	@if $align == 'start'{
		align-items: flex-start;
		-ms-flex-align: start;
	}@else if $align == 'end'{
		align-items: flex-end;
		-ms-flex-align: end;
	}@else if $align == 'center'{
		align-items: center;
		-ms-flex-align: center;
	}@else if $align == 'stretch'{
		align-items: stretch;
		-ms-flex-align: stretch;
	}@else if $align == 'baseline'{
		align-items: baseline;
		-ms-flex-align: baseline;
	}
}//end safe-flex


///mixin safe flex size
@mixin safeFlexSize($grow, $shrink, $size){
	flex: $grow $shrink $size;
	-ms-flex: $grow $shrink $size;
}


///mixin safe flex order
@mixin safeFlexOrder($order){
	order: $order;
	-ms-flex-order: $order;
}

@mixin twoColumn($xAlign,$yAlign,$colSpace){
	width: 100%;
	@include bleed($colSpace);
	@include safeFlex(row,wrap,$xAlign,$yAlign);
	& > *{
		width: 100%;
		padding-left: $colSpace / 2;
		padding-right: $colSpace / 2;
		@include smallTab{
			width: 50%;
		}
	}
}

///mixin - three-column
@mixin threeColumn($xAlign,$yAlign,$colSpace){
	width: 100%;
	@include bleed($colSpace);
	@include safeFlex(row,wrap,$xAlign,$yAlign);
	& > *{
		width: 100%;
		padding-left: $colSpace / 2;
		padding-right: $colSpace / 2;
		@include smallTab{
			width: 50%;
		}
		@include tab{
			width: 33.333%;
		}
	}
}

///mixin - three-column
@mixin fourColumn($xAlign,$yAlign,$colSpace){
	width: 100%;
	@include bleed($colSpace);
	@include safeFlex(row,wrap,$xAlign,$yAlign);
	& > *{
		width: 100%;
		padding-left: $colSpace / 2;
		padding-right: $colSpace / 2;
		@include smallTab{
			width: 50%;
		}
		@include tab{
			width: 33.333%;
		}
		@include tab{
			width: 25%;
		}
	}
}

@mixin spacer($spaceMultiplier){
	margin-bottom: 1em * $spaceMultiplier;
}

@mixin bleed($bleedWidth){
	margin-left: $bleedWidth * -0.5;
	margin-right: $bleedWidth * -0.5;
	@include calc(width, "100% + "$bleedWidth);
}

@mixin topicsTree{
	.selected{
		font-weight: $regular;
	}
	ul{
		@include listReset;
		&.level-0{
			@include cssColumns(2,30px);
			& > *{
				display: block;
				-webkit-column-break-inside: avoid;
				page-break-inside: avoid;
				break-inside: avoid;
				overflow: hidden;
			}
		}
		//level 2 (first children)
		&.level-1{
			list-style-type: circle;
			padding-bottom: 20px;
			ul{
				list-style-type: disc;
				display: none;
				padding-bottom: 20px;
			}
			ul,li{
				margin-left: 30px;
			}
		}
		//other children
		h2{
			font-size: 20px;
			position: relative;
			padding: 3px 0 3px 20px;
			&:before{
				content: '';
				display: inline-block;
				width: 10px;
				height: 10px;
				background: $lightBlue;
				position: absolute;
				top: 10px;
				left: 0;
			}
		}
		ul{
			// last item selected in tree
			li a{
				color: $link !important;
				&:hover,&:focus{
					color: $hoverLink !important;
				}
				&:not(.term-controller){
					pointer-events: none;
					color: $black !important;
					text-decoration: none;
				}
				&.term-controller{
					text-decoration: underline;
					position: relative;
					&:after{
						content: ' »';
					}
				}
			}
		}//end nested ul
	}//end ul
}

@mixin edgeToEdge {
	margin-left: 2rem * -1;
	margin-right: 2rem * -1;
}
