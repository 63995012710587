/* =================
  FORMS
==================== */

//NOTE: Views exposed forms should be themed with their view in _views.scss
/* FORMS MIXINS
----------------- */

///mixin placholder
@mixin placeholder{
	$place-tint: .4;
	$place-color: $bodyFont;
	&::-webkit-placeholder{
		opacity: $place-tint;
		color: $place-color;
	}
	&::-moz-placeholder{
		opacity: $place-tint;
		color: $place-color;
	}
	&:-ms-input-placeholder{
		opacity: 1;
		color: $grey;
	}
	&::-webkit-input-placeholder{
		opacity: $place-tint;
		color: $place-color;
	}
}

///mixin - placeholderFocus
@mixin placeholderFocus{
	$place-focus-tint: .2;
	$place-focus-color: $bodyFont;
	&::-webkit-placeholder{
		opacity: $place-focus-tint;
		color: $place-focus-color;
	}
	&::-moz-placeholder{
		opacity: $place-focus-tint;
		color: $place-focus-color;
	}
	&:-ms-input-placeholder{
		opacity: 1;
		color: $lightGrey;
	}
	&::-webkit-input-placeholder{
		opacity: $place-focus-tint;
		color: $place-focus-color;
	}
}

///mixin textInput
@mixin textInput{
	border: 1px solid $borderRule;
	border-radius: 0;
	font-size: 15px;
	padding: 10px 6px 11px;
	width: 100%;
	@include placeholder;
	box-shadow: none;
	color: $darkBlue;
	&:focus{
		@include placeholderFocus;
	}
}

///mixin - required
@mixin required{
	&:after{
		content: '*';
		color: $redBad;
		position: relative;
		top: -1px;
		right: -.25em;
		@include xSmaller;
	}
}

///mixin - form-submit
@mixin formSubmit{
	@include browserWipe;
	background: $link;
	font-weight: $heavy;
	font-family: $sans-serif;
	text-transform: uppercase;
	padding: .75em 3em;
	width: auto;
	border-radius: 0;
	border: 0;
	color: $black;
	&:hover,&:focus{
		background: $hoverLink;
		@include transAll(.3s);
		color: $white;
	}
}

%customElement{
	position: relative;
	label{
		.right-left &{
			padding-left: 0;
			padding-right: 20px;
		}
	}
	input{
		position: absolute;
		opacity: 0;
		&:hover + span:before,&:focus + span:before,{
			background: rgba(13,125,156,0.3);
		}
		&:checked + span:before{
			background: $green;
		}
		input:checked:hover + span:before{
			background: rgba(81,131,2,0.6);
		}
	}
	span{
		padding-left: 5px;
		.right-left &{
			padding-left: 0;
			padding-right: 5px;
		}
		&:before{
			content: '';
			display: block;
			height: 18px;
			width: 18px;
			position: absolute;
			left: 0;
			top: 2px;
			border: 2px solid $link;
			.right-left &{
				left: auto;
				right: 0;
			}
		}
	}//end span
}//end %customElement

//text input theming
.js-form-type-textfield input{
	@include textInput;
}

//email
.js-form-type-email input{
	@include textInput;
}

//textareas
.js-form-type-textarea textarea{
	@include textInput;
}

.js-webform-type-radios{
	legend{
		border-color: $borderRule;
		font-weight: $heavy;
		font-size: 18px;
		color: $black;
		padding: 0 0 .5em;
	}
}

//move input inline with label
.js-form-type-radio{
	width: auto;
	margin-right: 1em;
	@extend %customElement;
	span:before{
		border-radius: 50%;
	}
	label,input{
		display: inline-block;
	}
	label{
		font-weight: $regular;
		margin-bottom: 0;
	}
	input[type="radio"]{
		margin-right: .3em;
		position: relative;
		top: -2px;
	}
}


.form-checkboxes{
	fieldset &{
		margin-left: -1em;
		margin-right: -1em;
	}
}
.js-webform-checkboxes-other.form-item.panel.panel-default{
	.panel-heading{
		padding: 0 0 .5em;
		.panel-title{
			font-weight: $heavy;
			font-size: 18px;
			color: $black;
			padding: 0;
			margin: 0;
		}
	}
	.panel-body{
		padding: 1em 0 0 ;
	}
}


//move input inline with label
.js-form-type-checkbox{
	width: auto;
	margin-right: 1em;
	margin-top: 10px;
	.right-left &{
		margin-right: 0;
		margin-left: 1em;
	}
	@include tab{
		margin-top: 0;
	}
	@extend %customElement;
	label,input{
		display: inline-block;
	}
	label{
		font-weight: $regular;
		margin-bottom: 0;
	}
	input[type="checkbox"]{
		margin-right: .3em;
	}
}


/* 
	SELECT INPUT
------------------ */

@mixin inputSelect{
	width: 100%;
	font-size: 16px;
	border: 1px solid $borderRule;
	border-radius: 0;
	height: auto;
	&.select2--container--focus{
		box-shadow: 0 2px 2px rgba(35,35,35,0.3);
	}
}


/* SELECT STYLING
------------------- */
//hide optgroup safari bug fix
optgroup:empty{
	display: none;
}

//hide custom optgroup labels (ios10 bug fix)
.select2-results__group{
	display: none !important;
}

//override script set width
.select2-container--default{
	width: 100% !important;
	max-width: 250px;
}


/* SINGLE SELECT 2
----------------- */
body{
	.select2-container--default{
		//single filter
		.select2-selection--single{
			@include inputSelect;
			.select2-selection__choice__remove{
				position: relative;
				z-index: 999;
			}
			//rendered selection
			.select2-selection__rendered{
				padding: 4px 32px 4px 10px;
				color: $grey;
				text-align: left;
			}
			.select2-selection__arrow{
				top: 20px;
				right: 10px;
				@include triangle(down,9px,10px,$midnight);
				//hide default select2 arrow
				b{
					display: none;
				}
			}
		}//end single
	}//end select2 container default
	&.right-left .select2-container--default{
		.select2-selection--single{
			.select2-selection__rendered{
				text-align: right;
			}
			.select2-selection__arrow{
				left: 10px;
			}
		}
	}
}//end body


/* SELECT 2 MULTI
------------------ */
body{ 
	.select2-container--default{

		//multi filter
		.select2-selection--multiple{
			@include inputSelect;
			padding: 3px 0 3px 0;
			.select2-selection__choice__remove{
				position: relative;
				z-index: 999;
			}
			//selection input
			.select2-search--inline{ 
				position: relative;
				float: none;
				padding: 2px 0 2px 3px;
				width: 100%;
				&:after{
					content: '';
					display: block;
					cursor: pointer;
					position: absolute;
					top: 18px;
					right: 3px;
					@include triangle(down,9px,10px,$midnight);
				}
				input.select2-search__field{
					@include placeholder;
					&:focus{
						@include placeholderFocus;
					}
				}
			}

			//selected item
			.select2-selection__choice{
				border-radius: 0;
				background-color: $offWhite;
			}
		}//end multi
	}//end select2 conatiner default
	&.right-left .select2-container--default{
		.select2-selection--multiple{
			.select2-search--inline{
				&:after{
					left: 2px;
					right: auto;
				}
			}
		}
	}
}//end body

/* SELECT 2 ACTIVE
-------------------- */
body .select2-container--default{
	//active select2
	&.select2-container--open,&.select2-container--focus{

		//multiple
		.select2-selection--multiple{			
			.select2-search--inline{ 
				input.select2-search__field{
					@include placeholder;
					&:focus{
						@include placeholderFocus;
					}
				}
			}
		}

	}//end active
}


/* SELECT 2 DROPDOWNS
---------------- */
.select2-container{
	.select2-dropdown{
		border: 1px solid $borderRule;
		border-radius: 0;
		border-top: 0;
		padding: 0;
	}
}
.select2-container--default .select2-results__option[aria-selected="true"]{
	background-color: $offWhite;
}
.select2-container--default .select2-results__option--highlighted[aria-selected]{
	background-color: $link !important; 
}


/* REQUIRED STAR
----------------- */
.form-required{
	&:after{
		position: relative;
		top: -8px;
		left: 2px;
		background-size: 7px 7px;
		height: 7px;
		background-repeat: no-repeat;
	}
}


/* USER LOGIN FORM
-------------------- */
.user-login-form{
	padding-top: 2em;
	padding-bottom: 3em;
	.form-type-textfield{
		@include spacer(1.5);
		input[type="text"]{
			@include textInput;
			max-width: 300px;
			min-height: 40px;
		}
	}
	.form-type-password{
		@include spacer(1.5);
		input[type="password"]{
			@include textInput;
			max-width: 300px;
			min-height: 40px;
			@include spacer(1.5);
		}
	}
}//end user login form


/* webforms
-------------------- */
.path-webform{ 
	.page-header,.breadcrumb,.alert{
		max-width: $maxWidthText;
		@include auto;
	}
	form:not(#site-search-form){
		padding-top: 2em;
		max-width: $maxWidthText;
		@include auto;
		@include spacer(3);
		.form-group,.fieldgroup{
			@include spacer(1.5);
		}
	}
}//end webforms


/* WEBFORM SPECIFIC ELEMENTS
------------------------- */
.webform-submission-form{
	.form-item.form-group,.fieldgroup.form-wrapper{
		@include spacer(1.5);
	}
	.js-webform-type-radios{
		legend{
			margin-bottom: 0;
		}
	}
	.form-wrapper.webform-section:not(:first-child){
		margin-top: 3em;
	}
}





