/* =================
  BLOCKS
==================== */
///mixin placholder
@mixin searchBlockPlaceholder{
	$place-tint: 1;
	$place-color: $white;
	&::-webkit-placeholder{
		opacity: $place-tint;
		color: $place-color;
	}
	&::-moz-placeholder{
		opacity: $place-tint;
		color: $place-color;
	}
	&:-ms-input-placeholder{
		opacity: $place-tint;
		color: $place-color;
	}
	&::-webkit-input-placeholder{
		opacity: $place-tint;
		color: $place-color;
	}
}

///mixin - placeholderFocus
@mixin searchBlockPlaceholderFocus{
	$place-focus-tint: .2;
	$place-focus-color: $white;
	&::-webkit-placeholder{
		opacity: $place-focus-tint;
		color: $place-focus-color;
	}
	&::-moz-placeholder{
		opacity: $place-focus-tint;
		color: $place-focus-color;
	}
	&:-ms-input-placeholder{
		opacity: $place-focus-tint;
		color: $place-focus-color;
	}
	&::-webkit-input-placeholder{
		opacity: $place-focus-tint;
		color: $place-focus-color;
	}
}

/* ALERT BANNER
------------------ */
.block-alert-banner{
	position: relative;
	padding: 1em 70px;
	display: none;
	min-height: 70px;
	&:after{
		@include fullBack($lightBlue);
	}
	.close-wrapper{
		position: absolute;
		top: 0;
		right: 0;
		height: 50px;
		width: 50px;
		.close-alert{
			display: block;
			width: 100%;
			height: 100%;
			color: $grey;
			font-size: 28px;
			background: $offWhite;
			cursor: pointer;
			@include safeFlex(column,null,center,center);
			&:hover,&:focus{
				text-decoration: none;
				background: $grey;
				color: $offWhite;
			}
		}
		.close-text{
			font-size: 16px;
			color: $white;
			position: absolute;
			bottom: -24px;
			left: 0;
			width: 100%;
			text-align: center;
		}
	}//end close wrapper
	.field--name-body{
		text-align: center;
		@include lightBack;
		margin-bottom: 0 !important;
		max-width: $maxWidthText;
		@include auto;
		& > *:last-child{
			margin-bottom: 0;
		}
	}
}//end alert banner

/* LANGUAGE SWITCHER
----------------------- */
#block-languageswitcher{
	position: relative;
	z-index: 1;
	width: 100%;
	padding-left: 10px;
	&:after{
		@include fullBack($darkBlue);
	}
	ul.links{
		position: relative;
		z-index: 2;
		@include listReset;
		li{
			display: inline-block;
			a{
				display: block;
				color: $white;
				padding: 15px 10px;
				font-size: 15px;
				text-decoration: underline;
				background-color: $darkBlue;
				&:hover,&:focus{
					color: $transWhite;
				}
				&.is-active{
					text-decoration: none;
					color: $transWhite;
					&:hover,&:active{
						color: $white;
					}
				}
			}
		}

		.dv-alert{
			position: absolute;
			right: 30px;
			top: 0px;
			font-weight: $heavy;
			@include mobileMenu{
				right: 20px;
			}
			@include onlyPhone{
				display: none;
			}
			@include superMaxWidth{
				right: 50px;
			}
			&:hover,&:focus{
				color: $transWhite;
			}
			&.is-active{
				text-decoration: none;
				color: $transWhite;
				&:hover,&:active{
					color: $white;
				}
			}
		}

	}
}//end language switcher

/* HEADER SEARCH BLOCK
------------------- */
#block-sitesearch{
	//hide from providers
	.role-provider:not(.role-administrator):not(.role-client_developer):not(.role-content_administrator) &{
		display: none;
	}

	//search icon
	.t-search{
		font-size: 16px;
		text-indent: -300vw;
		width: 60px;
		height: 40px;
		cursor: pointer;
		display: block;
		background-color: $lightBlue;
		background-image: url(../images/svg/search-toggle-white.svg);
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 22px 22px;
		border: 1px solid transparent;
		&:hover{
			background-color: $hoverLink;
		}
		&:focus{
			border: 1px solid $borderRule;
		}
		@include deskMenu{
			width: 110px;
			height: 54px;
			background-image: url(../images/svg/search-toggle-white.svg);
			background-repeat: no-repeat;
			background-position: center center;
			background-size: 30px 30px;
		}
	}

	//close search
	.t-search-close{
		position: absolute;
		top: 0;
		right: 0;
		height: 50px;
		width: 50px;
		display: none;
		z-index: 502;
		@include deskMenu{
			height: 70px;
			width: 70px;
		}
		.close-search{
			display: block;
			width: 100%;
			height: 100%;
			color: $grey;
			font-size: 28px;
			background: $offWhite;
			cursor: pointer;
			@include safeFlex(column,null,center,center);
			&:hover,&:focus{
				text-decoration: none;
				background: $grey;
				color: $offWhite;
			}
		}
		.close-text{
			font-size: 18px;
			color: $white;
			position: absolute;
			bottom: -24px;
			left: 0;
			width: 100%;
			text-align: center;
		}
	}//end search close


	//put search form wrapper at top of screen and full width, hide for slide effect
	.search-form-wrapper{
		position: absolute;
		display: none;
		z-index: 500;
		top: 0;
		@include mobileMenu{
			width: 100%;
		}
		@include deskMenu{
			left: -50vw;
			right: -50vw;
		}
	}

	//put search form at top of screen and full width
	#site-search-form {
		position: relative;
		width: 100%;
		padding: 80px 20px;
		text-align: center;
		background: $lightBlue;
		@include deskMenu{
			padding: 160px 20px;
		}

		//search box input
		.js-form-item-site-search-api-fulltext{
			padding: 10px 0;
			width: 100%;
			margin-bottom: 0;
			max-width: 800px;
			@include auto;
			label{

			}
			input{
				width: 100%;
				background: transparent;
				padding: 9px 0;
				border: none;
				color: $white;
				border-bottom: 1px solid $white;
				border-radius: 0;
				box-shadow: none;
				height: auto;
				font-size: 20px;
				@include searchBlockPlaceholder;
				//prevent yellow chrome autofill inset
				&:-webkit-autofill{
					-webkit-box-shadow: 0 0 0 1000px $white inset;
				}
				&:focus{
					@include searchBlockPlaceholderFocus;
				}
				@include tab{
					font-size: 28px;
				}
				@include deskMenu{
					font-size: 42px;
				}
			}
		}

		//search submit button
		.form-actions{
			position: relative;
			@include auto;
			max-width: 800px;
			display: block;
		}
		.form-submit{
			position: absolute;
			right: 0;
			top: -46px;
			color: $white;
			text-indent: 9000px;
			border: none;
			height: 26px;
			width: 26px;
			background: url(../images/svg/search-toggle-white.svg);
			background-repeat: no-repeat;
			background-position: 0 0;
			background-size: 26px 26px;
			padding: 0;
			&:hover{
				@include opacity(.7);
			}
			.right-left &{
				right: auto;
				left: 0;
			}
			@include tab{
				height: 30px;
				width: 30px;
				background: url(../images/svg/search-toggle-white.svg);
				background-repeat: no-repeat;
				background-position: 0 0;
				background-size: 30px 30px;
			}
			@include deskMenu{
				top: -64px;
				height: 36px;
				width: 36px;
				background-size: 36px 36px;
			}
		}
	}//end search block form
}//end search block


/* LSC DISCLAIMER
---------------------- */
#block-lscdisclaimer{
	text-align: center;
	padding-top: 100px;
	padding-bottom: 2em;
	position: relative;
	max-width: 600px;
	@include auto;
	min-height: 204px;
	@include smallTab{
		text-align: left;
		padding-top: 50px;
		min-height: 160px;
	}
	@include tab{
		min-height: 0;
		padding-bottom: 2.5em;
	}
	&:after{
		@include fullBack($dirtyWhite);
	}
	.lsc-logo{
		display: block;
		width: 178px;
		height: 48px;
		position: absolute;
		top: 40px;
		@include calc(left, "50% - 89px");
		@include smallTab{
			left: 0;
			top: 50px;
		}
		@include tab{
			top: 46px;
		}
		a{
			display: block;
			width: 100%;
			height: 100%;
			text-indent: -600vw;
			color: transparent;
			background: url(../images/lsc-logo-new.png) no-repeat center;
			background-size: contain;
		}
	}
	.field--name-body{
		margin-bottom: 0 !important;
		p{
			font-size: 16px;
			@include smallTab{
				padding-left: 195px;
				.right-left &{
					text-align: right;
				}
			}
		}
	}
}//end lsc disclaimer


/* BROWSE ALL CHECKLISTS
---------------------- */
#block-bootsass-child-browseallchecklists{
	text-align: center;
	padding-bottom: 2em;
	position: relative;
	max-width: 600px;
	padding: 50px 0;
	@include auto;
	min-height: 204px;
	@include smallTab{
		text-align: left;
		min-height: 160px;
	}
	@include tab{
		min-height: 0;
		padding: 81px 0;
	}
	&:after{
		@include fullBack($white);
		border-top: 1px solid $dirtyWhite;
	}
	h2{
		color: $green;
		margin-bottom: 24px;
		font-size: 32px;
		@include tab{
			font-size: 40px;
		}
	}
	.field--name-body{
		margin-bottom: 0;
	}
	a{
		background-color: $darkBlue;
		padding: 21px 13px;
		font-size: 18px;
	}
}//end lsc disclaimer


/* footer social media
-------------------- */
#block-socialmedia{
	margin: 1em 0;
	ul:not(.contextual-links){
		@include listReset;
		@include safeFlex(row,wrap,center,null);
		@include bleed(10px);
		li{
			padding-left: 5px;
			padding-right: 5px;
			a{
				color: #fff;
				display: block;
				text-indent: -300vw;
				width: 48px;
				height: 48px;
				&:hover,&:focus{
					@include transClear;
					@include opacity(.7);
				}
				&.facebook{
					@include svgBack(footer-facebook,48px,48px);
				}
				&.twitter{
					@include svgBack(footer-twitter,48px,48px);
				}
				&.instagram{
					@include svgBack(footer-instagram,48px,48px);
				}
				&.youtube{
					@include svgBack(footer-youtube,48px,48px);
				}
			}
		}//li
	}//ul
}//social media


/* footer menu
-------------------- */
#block-bootsass-child-footer{
	ul:not(.contextual-links){
		@include listReset;
		@include safeFlex(row,wrap,center,null);
		@include bleed(24px);
		@include desk{
			@include bleed(16px);
		}
		li{
			padding: 12px 14px;
			position: relative;
			@include desk{
				padding: 0 8px;
			}
			&:not(:last-child){
				&:not(:nth-last-child(2)){
					&:after{
						@include vertRule(right,50%,$white);
						@include desk{
							@include vertRule(right,80%,$white);
						}
						.right-left &{
							@include vertRule(left,50%,$white);
							right: auto;
							@include desk{
								@include vertRule(right,80%,$white);
							}
						}
					}
				}
			}
			&.mobile-dv-alert{
		    margin-top: 20px;
		    width: 100%;
		    padding-left: 10px;
		    padding-right: 10px;
				@include smallTab{
					display: none;
				}
				a{
					display: block;
					color: $green;
					font-weight: bold;
					text-align: center;
					font-size: 20px;
					background: $white;
		    	padding: 10px;
					@include desk{
						font-size: 18px;
					}
					&:hover,&:focus{
						background: $offWhite;
						color: $green;
						text-decoration: underline;
					}
				}
			}
			a{
				color: $white;
				padding: 0;
				&:hover,&:focus{
					color: $white;
					text-decoration: underline;
				}
				&.is-active{
					color: $midnight;
					text-decoration: underline;
					&:hover,&:focus{
						text-decoration: none;
					}
				}
			}
		}//li
	}//ul
}//social media

/* COPYRIGHT
--------------------- */
#block-copyright{
	text-align: center;
	padding-top: 1em;
	padding-bottom: 2.5em;
	.field--name-body{
		@include spacer(0);
		p{
			font-size: 16px;
			color: $white;
		}
	}
}//end copyright

/* HOMEPAGE BANNER
-------------------- */
.banner-image{
  width: calc(100% + 40px);
  background-size: cover !important;
  background-position: 0 -190px !important;
  @include edgeToEdge;
  @include mobileMenu{
    height: 56.25vw;
    min-height: 280px;
    max-height: 470px;
  }
  @include deskMenu{
    width: calc(100% + 40px);
    @include safeFlexOrder(2);
    min-height: 470px;
  }
}//end banner image

/* HOMEPAGE INTRO
-------------------- */
.block-homepage-intro{
	position: relative;
	max-width: 2400px;
	@include calc(width, "100% + 80px");
	@include edgeToEdge;
	@include deskMenu{
		@include safeFlex(row,wrap,end,stretch);
		&:after,&:before{
			content: none;
		}
	}
	@include ultraMaxWidth{
		width: 100%;
		@include auto;
	}
	.intro-image{
		width: 50%;
    background-size: cover !important;
		@include mobileMenu{
			height: 56.25vw;
			min-height: 280px;
			max-height: 470px;
		}
		@include deskMenu{
			width: 50%;
			@include safeFlexOrder(2);
			min-height: 470px;
		}
	}//end intro image

  .homeintro-inner{
		padding: 2em 20px 2.5em;
		@include mobileMenu{
			width: 100%;
			text-align: center;
			max-width: 520px;
			@include auto;
		}
		@include deskMenu{
			width: 50%;
			max-width: 880px;
			padding: 3em 80px 3.5em;
			@include safeFlexOrder(1);
			@include safeFlex(column,null,center,null);
		}
		@include maxWidth{
			min-height: 470px;
			background: $siteBackground;
		}
		@include superMaxWidth{
			min-height: 600px;
			padding: 3em 120px 3.5em 145px;
		}
		h1{
			font-size: 40px;
			color: $green;
			@include superMaxWidth{
				font-size: 56px;
			}
		}
		.field--name-body{
			@include spacer(1.5);
			@include superMaxWidth{
				font-size: 24px;
			}
		}
		.intro-button{
			@include spacer(1.5);
			.button{
				width: 100%;
				max-width: 360px;
				font-size: 24px;
				padding: .75em 1em;
				@include superMaxWidth{
					font-size: 26px;
				}
			}
		}//end intro button
		.field--name-post-text{
			@include superMaxWidth{
				font-size: 24px;
			}
		}
	}//end home intro inner
}//end homepage intro

/* CALLOUT BLOCK
------------------- */
.block-callout-block{
	@include spacer(2);
	max-width: 600px;
	@include auto;
	.field--name-callout-image{
		@include edgeToEdge;
		@include smallTab{
			@include edgeReset;
		}
		img{
			width: 100%;
		}
	}
}//end callout block

/* DOMESTIC VIOLENCE MESSAGE
------------------- */
#dv-message{
	position: fixed;
	top: 10%;
	right: 10%;
	z-index: 9999;
	display: none;
	width: 80%;
	height: 80%;
	overflow-y: scroll;
	background: $white;
	border: 1px solid $borderRule;
	box-shadow: 3px 3px 5px rgba(35,35,35,0.4);
	padding: 40px 20px 20px;
	@include smallTab{
		position: absolute;
		top: 100%;
		right: 9px;
		width: 70%;
		max-width: 450px;
		height: auto;
	}
	.message-close{
		@include safeFlex(column,nowrap,center,center);
		width: 26px;
		height: 26px;
		position: absolute;
		top: 0;
		right: 0;
		font-weight: $heavy;
		background: $offWhite;
		&:hover,&:focus{
			text-decoration: none;
			background: $grey;
			color: $offWhite;
		}
	}
}//end dv message

/* SUBSCRIBE WEBFORM (TWILIO)
---------------------- */
#block-bootsass-child-webform{
	@include safeFlex(row,wrap,justify,center);
	padding: 89px 0;
	color: $white;
	&:before{
		display: none;
	}
	&:after{
		@include fullBack($darkBlue);
	}
	h2.block-title{
		color: $white;
		font-size: 36px;
		margin-bottom: 0;
	}
	form.webform-submission-twilio-sms-opt-in-form{
		@include safeFlex(row,wrap,start,center);
	}
	.form-item-phone{
		@include safeFlex(column,wrap,start,start);
		label{
			font-size: 20px;
			margin-bottom: 6px;
			&::after{
				display: none;
			}
		}
	}
	.form-group{
		margin-bottom: 0;
	}
	button.form-submit{
		background-color: $white;
    color: $black;
		font-size: 18px;
    height: 54px;
    padding: 0;
    width: 142px;
		margin: 0 0 -32px 16px;
		&:hover,:focus{
			text-decoration: underline;
		}
	}
	input.form-tel{
		border-radius: 0;
		border: 0;
		box-shadow: none;
		font-size: 18px;
		padding: 27px 12px;
		width: 470px;
	}
}//end subscribe webform

/* CHECKLISTS BREADCRUMB
---------------------- */
.breadcrumb.checklists-breadcrumb{
	max-width: 1200px;
	@include auto;
}
