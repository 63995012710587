/* =================
  WIDGETS
==================== */

/* GLOBAL
------------- */
.field--name-oc-widgets{
	@include spacer(2);
	& > .field--item{
		padding: 1em 0;
		max-width: $midWidth;
		@include auto;
		&:first-child{
			padding: 0 0 1em;
			& > .paragraph:not(.paragraph--type--view-placer){
				margin-top: 3em;
			}
		}
	}
}

h2.widget-title{
	font-size: 24px;
	text-align: center;
	letter-spacing: 1px;
	text-transform: uppercase;
	@include spacer(1);
}

/* SCHEDULE WIDGET
------------------- */
//used for location schedule info on services // loon
.paragraph--type-schedule{
	.field--type-office-hours{
		width: 100%;
		white-space: normal;
		.office-hours__item-slots{
			max-width: 200px;
		}
	}
}

/* ACCORDIONS
----------------- */
.paragraph--type--accordion-group{
	.wrapper{
		max-width: $maxWidthText;
		@include auto;
	}
}//end accordion group

.accordion-item{

	//accordion header bar
	.accordion-header{
		border-top: 1px solid;
		border-color: $borderRule;
		position: relative;
		font-size: 22px;
		text-transform: none;
		margin-bottom: 0;
		z-index: 3;
		a{
			color: $link;
			display: block;
			padding: 1em 40px 1em 0;
			.right-left &{
				padding: 1em 0 1em 40px;
			}
		}
		&:after{
			position: absolute;
			right: 0;
			top: 20px;
			z-index: -1;
			font-style: normal;
	    font-variant: normal;
	    text-rendering: auto;
	    -webkit-font-smoothing: antialiased;
			font-family: "Font Awesome 5 Free";
			content: "\f078";
			display: block;
			height: 30px;
			width: 30px;
			line-height: 30px;
			border-radius: 6px;
			background: $link;
			text-align: center;
			color: $white;
			font-size: 18px;
			.right-left &{
				right: auto;
				left: 0;
			}
		}
		&:hover,&:focus{
			a{
				color: $green;
			}
			&:after{
				background: $green;
			}
		}
	}

	//accordion content
	.accordion-content{
		position: relative;
		top: 0;
		display: none;
		overflow: hidden;
		padding-bottom: 1em;
		a{
			word-break: break-word;
		}
	}

	//active accordion
	&.accord-active{
		.accordion-header{
			&:after{
				content: "\f077";
			}
		}
	}//end active

	//no-js fallback
	.no-js &{
		&:hover,&:focus{
			.accordion-content{
				display: block;
			}
		}
	}
}//end accordion-item


/* BLOCK PLACER
-------------------- */
.paragraph--type--block-placer{
	max-width: $maxWidthText;
	@include auto;
}

/* IFRAME WIDGET
----------------- */
.paragraph--type--iframe{
	max-width: $maxWidthText;
	@include auto;
	.iframe-embed-code{
		&.responsive{
			iframe{
				width: 100% !important;
			}
		}
	}
}//end iframe


/* IMAGE WIDGET
----------------------- */
.paragraph--type--image{
	max-width: $maxWidthText;
	@include auto;
	.field--name-field-image{
		margin-bottom: .5em !important;
		@include onlySmall{
			@include edgeToEdge;
		}
		img{
			width: 100%;
		}
	}//end image field
}//end image widget


/* LINKS & FILES WIDGET
----------------------- */
.paragraph--type--links-files{
	max-width: $maxWidthText;
	@include auto;
	.links-files{
		@include smallTab{
			@include cssColumns(2,20px);
		}
	}//end image field
}//end image widget


/* TEXT WIDGET
----------------------- */
.paragraph--type--text{
	max-width: $maxWidthText;
	@include auto;
}//end image widget


/* VIDEO WIDGET
----------------------- */
.paragraph--type--video{
	max-width: $maxWidthText;
	@include auto;
}//end image widget


/* WEBFORM
-------------------- */
.paragraph--type--webform-placer{
	max-width: $maxWidthText;
	@include auto;
}

/* CHECKLISTS
----------------- */
.paragraph--type--checklist-group{
	background-color: $greenWhite;
	&.checklist-complete{
		h2.widget-title{
			background-color: $greenWhite;
			color: $darkGrey;
			text-decoration: line-through;
		}
		& > .wrapper{
			border-top: 2px solid $darkGrey;
		}
	}
	h2.widget-title{
		background-color: $darkBlue;
		color: $white;
		text-transform: none;
		text-align: left;
		padding: 18px 70px 18px 50px;
		font-size: 22px;
		margin-bottom: 0;
		@include tab{
			font-size: 28px;
			padding: 20px 84px 20px 70px;
		}
		@include desk{
			font-size: 36px;
			padding: 24px 96px 24px 80px;
		}
	}
	a{
		color: $darkBlue;
		&:hover,&:focus{
			color: $green;
		}
	}
	& > .wrapper{
		padding: 22px 24px 24px 24px;
		border-top: 2px solid $greenWhite;
		@include tab{
			padding: 30px 32px 32px 32px;
		}
		@include desk{
			padding: 38px 40px 40px 40px;
		}
	}
	.field--name-checklist {
		& > .field--item:not(:last-of-type){
			margin-bottom: 40px;
		}
	}
	.field--name-ec-long-text,
	.field--type-text-long{
		margin-bottom: 40px;
		&::after{
			display: none;
		}
	}
	.paragraph--type--checklist-item{
		.field--name-ec-long-text,
		.field--name-oc-links{
			display: none;
		}
	}
	.field--name-checklist-title{
		font-weight: $heavy;
		position: relative;
		@include safeFlex(column,nowrap,center,start);
		margin-bottom: 0;
		padding-right: 28px;
		padding-left: 72px;
		min-height: 48px;
		@include tab{
			padding-right: 36px;
		}
		&.complete{
			.item-checkbox{
				&::before{
					content: url(../images/check.svg);
				}
			}
		}
	}
	.item-checkbox{
		position: absolute;
		left: 0;
		top: 0;
		height: 48px;
		width: 48px;
		background-color: $white;
		border: 3px solid $darkBlue;
		border-radius: 12px;
	}
	img.checklist-icon{
		height: 18px;
		width: 18px;
		margin-right: 0.35em;
	}
	.inner-checklist-accordion{
		cursor: pointer;
		position: absolute;
		right: -18px;
		top: -12px;
		@include transAll(.3s);
		@include tab{
			right: 0;
			top: -16px;
		}
		&::before{
			display: block;
			content: '';
			height: 48px;
			width: 48px;
			background-size: 48px 48px;
			background-image: url(../images/chevron.svg);
			background-repeat: no-repeat;
			@include tab{
				height: 60px;
				width: 60px;
				background-size: 60px 60px;
			}
		}
		&.active{
			transform: rotate(180deg);
		}
	}
	.checklist-bottom{
		font-weight: $regular;
		margin-left: -60px;
		@include tab{
			margin-left: 0;
		}
	}
}//end accordion group
